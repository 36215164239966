
%MainCTA{
    @extend %btnPrimary;
    @extend %etype-1;
}

%btnPrimary {
    width: 100%;
    background-color: $button-prime;
    color: $grey-10;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
        background-color: $hover-button-prime;
    }
    &:active {
        background-color: $click-button-prime;
    }
}

%btnSecondary {
    width: 100%;
    background-color: $button-secondary;
    color: $grey-10;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
        background-color: $hover-button-secondary;
    }
    &:active {
        background-color: $click-button-secondary;
    }
}

%btnFocus {
    width: 100%;
    background-color: $button-focus;
    color: $grey-10;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
        background-color: $hover-button-focus;
    }
    &:active {
        background-color: $click-button-focus;
    }
}
