
.cell-more-actions{
    // margin-left: auto;
    stroke: $text-light-01;
    color: $text-light-01;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    flex:1;
    justify-content: center;
    :hover {
        >svg{
            stroke-width: 1.5px;
        }
    }
    .clickable{
        @include icon-box-40;
        background-color: inherit;

    }
    .more-modal{
        position: absolute;
        // border-bottom-left-radius: 8px;
        // border-bottom-right-radius: 8px;
        border: 1px solid #005CB9;

        background-color: $white;
        z-index: 100;
        right: 8px;
        bottom: 0px;
        transform: translateY(100%);
        .top{
            display: flex;
            padding: 0.75rem 1.5rem;
            >div+div{
                margin-left: 0.75rem;
            }
            &.border {
                border-bottom: 1px solid grey;
            }
        };
        .secondary-action{
            padding: 0.75rem 1.5rem;
            display: flex;
            align-items: center;
            white-space: nowrap;
            cursor: pointer;
            @include action-color-hover;
            p {
                margin-left: 0.75rem;
            }
        }
        
    }
    &.above{
        .more-modal{
            bottom: unset;
            top: 0;
            transform: translateY(-100%);
        }
        
    }
}


.multy-select-modal{
    position:fixed;
    z-index: 100;
    bottom: 5vh;
    right: calc(6vh + 5.25rem);
    border: 1px solid #0043CE; //@TODO: replace with var;
    box-sizing: border-box;
    background: $white;
    // border-radius: 4px;
    padding: 1.5rem;
    @media (max-width:400px){
        right: 24px;
        bottom:24px;
        width: calc(100% - 48px);
        box-shadow: 0 3px 10px rgba(0,0,0,.25);
    }
    .title{
        font-weight: bold;
        font-size: 16px;
    }
    .selected{
        font-size: 14px;
    }
    .line{
        background: #E0E0E0;
        height: 1px;
        margin: 0.75rem 0;
    }
    .actions-title{
        margin-bottom: 0.75rem;
    }
    .action{
        display: flex;
        align-items: center;
        cursor: pointer;
        @include action-color-hover;
    }
    .icon{
        @include icon-box-40;
        border: 1px solid #E0E0E0;
        margin-right: 0.75rem;
    }
    .action + .action {
        margin-top: 0.75rem;
    }
}