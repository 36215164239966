.avatar-input-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .circle{
        position:relative;
        @include circle(120px);
        background-color: #86C2FF;
    }
    .upload{
        position: absolute;
        z-index: 1;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color: red;
        opacity: 0;
        @include circle(120px);
        height: 130px;
        overflow:hidden;
        cursor:pointer;
        &.disabled{
            pointer-events: none;
        }
    }
    .img-wrap{
        position: absolute;
        z-index: 1;
        top:0;
        left:0;
        right:0;
        bottom:0;
        @include circle(120px);
        overflow:hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            height: 120px;
            width: auto;
            max-width: none;
        }
    }
    .circle-btn{
        width:24px;
        height:24px;
        display:flex;
        border-radius:50%;
        background-color:$white;
        box-sizing:border-box;
        cursor:pointer;
        position: absolute;
        z-index: 2;
        &.add{
            left: 50%;
            bottom: -12px;
            transform: translateX(-50%);
            pointer-events: none;
        }
        &.del{
            right: 0px;
            top: 5px;
            padding:4px;
        }
    }
    .av-label{
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0em;
        color:$white;
        width:120px;
        height:120px;
        display:flex;
        justify-content:center;
        align-items:center;
    }
    &.is-disabled{
        .circle{
            background-color: #f3f3f3;
        }
        .upload{
            cursor: default;
        }
        .circle-btn{
            display: none;
        }
        .img-wrap{
            img{
                filter: grayscale(1);
            }
        }
        .av-label{
            color:$text-light-02;
        }
    }
}