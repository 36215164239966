//Normal level order >;
$level-colors: $all-good, $alert-danger, $alert-critical;

@for $i from -1 to length($level-colors) {
    .level-color-#{$i} {
        @if ($i < 0) {
            background-color: $detailstype;
        }@else{
            background-color: nth($level-colors, $i+1);
        }
    }
}

//CODE REVIEW: is there any better solution?;
@for $i from -1 to length($level-colors) {
    .label-color-#{$i}{
        @if $i < (length($level-colors) - 1) {
            color: $purewhite;
        }@else{
            color: $darkesttype;
        }
    }
}

.deadline{
    flex: 1;
    .base{
        display: flex;
        background-color: $greybkg;
        align-items: center;
        @include border-radius;
        position: relative;
    }
    .level{
        height: 1.5rem;
        @include border-radius;
    }
    .title-container{
        color: $darkesttype;
        @include ptype0; 
    }
    .label-container{
        position: absolute;
        padding-left: .5rem;
        @include ptype-2;
    }
}
