@import "style/colors";
@import "style/typography";
@import "style/_media";
$modal-Xmargin: 80px;
$modal-top-margin: 40px;
$main-padding: 40px;

@mixin make-it-a-table {
  .make-it-a-table{
    display: grid;
    grid-template-columns: 10fr repeat(2, 3fr); //repeat(2, 4fr);
    &.two-col{
      grid-template-columns: 10fr repeat(1, 3fr); //repeat(2, 4fr);
    }
    >span{
        @include ptype-5-strong;
        padding-left: 0.5rem;
    }
    >div{
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
        padding: 0.5rem;
        margin-top: 0.5rem;
    }
    >div:nth-child(5n){
        border-right: 1px solid #e6e6e6;
    }
    >div:nth-child(5n+1){
        border-left: 1px solid #e6e6e6;
    }
    .magic-input{
        width: auto ;
    }
    .bar{

        border-left: 1px solid $text-light-03;
        padding-left: 12px;
        margin-left: 12px;
      }
      .remove-container{
        >button{
            margin-left:auto !important;
        }
      }
}
}

@mixin section-indicator (){
  .selection-indicator{
    // position: sticky;
    // z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background-color: #ECF5FF;
    // width: calc(100% + 2 * #{$main-padding});
    // transform: translateX(-$main-padding);
    padding: 18px 38px 18px 48px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    color:#333333;
    @extend %ptype-5-strong;
    .see-all{
      color:#005CB9;
      @extend %ptype-5;
      border:0;
      background-color: transparent;
      padding:0;
      outline: none;
      cursor: pointer;
    }
  }
}
.list-modal-wrapper,.wizzard-form-wrapper  {
  background-color: $white;
  width: 100%;
  max-width: 840px;
  $coll-number: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  @include make-it-a-table;
  >.header{
    flex-shrink: 0;
    padding: $main-padding;
    // padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    > .title {
      @extend %etype-2;
      color: $text-light-01;
      padding:0;
      border-bottom:0;
      min-height: auto;
    }
    > .close {
      border: 0;
      background-color: transparent;
      padding: 0;
      width: 32px;
      height: 32px;
      outline: none;
      stroke: none;
    }
  }
  .modal-body, .form-body{
    flex-shrink: 1;
    padding: $main-padding;
    padding-top: 0;
    padding-bottom: calc(100px); //TODO: review: datepicker's bottom part is hidden with only 100px (addPackWizzard-step1)
    // height: calc(100% - 112px); //remove header height

    &-no-modal{
      padding: 0;
    }
    overflow-y: auto;
    overflow-x: hidden;
    //overwrites for the current input versions
    .magic-input .inputContainer{
      height: 48px !important;
    }
    .magic-input{
      &.search{
        margin-bottom: 8px !important;
      }
    }
    .cdt-button{
      border-radius: 0;
    }
    //end of overwrites
    &.grid{
      display: grid;
      gap: 1.5rem;
      grid-template-columns: repeat(#{$coll-number}, 1fr);
    }
    .top-row{
      width: 100%;
      display: flex;
      background: #ffffff;
      // padding: 10px 18px;
      box-sizing: border-box;
      margin-top: 10px;
      margin-bottom: 18px;
      margin-left: 4px;
      line-height: 16px;
      position: relative;
      @extend %ptype-5-strong;
      >div{
        margin-right: 12px;
      }
    }
    @include section-indicator();
    // >button{
    //   width: 100%;
    //   display: flex;
    //   min-height: 48px;
    //   background: $white;
    //   border: 1px solid $grey-40;
    //   padding: 14px 18px;
    //   box-sizing: border-box;
    //   margin-bottom: 4px;
    // }
    .main-item{
      width: 100%;
      display: flex;
      min-height: 48px;
      background: $white;
      border: 1px solid $grey-40;
      padding: 14px 18px;
      box-sizing: border-box;
      margin-bottom: 4px;
      line-height: 16px;
      position: relative;
      &::after {
        content: "";
        background: transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAGFBMVEVMTExZWVlmZmZzc3OZmZnl5eXy8vL///9ahuc/AAAARElEQVR42mNgJwAGQsGoAjZUJqYCZhYYi4UJqwJWBhaoPCO6CQgVCHmEAlQVcHmEAjQVMHmEAjQVQHn84cA2aONiVAEAy48bh8aX3D8AAAAASUVORK5CYII=") 0 0 no-repeat;
        width: 32px;
        height: 32px;
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 5px;
        transform-origin: center;
        transform: rotate(180deg);
      }
      svg {
        margin-right: 12px;
        cursor: pointer;
        stroke:none;
      }
      &.selected {
        user-select: none;
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }
  >.magic-input{
    padding-right: $main-padding;
    padding-left:$main-padding;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    padding: 32px $main-padding;
    background-color: $grey-10;
    gap: 1.5rem;
    &.right{
      justify-content: flex-end;
    }
    button{
      flex:0 1 220px;
    }
    // button {
    //   padding: 14px 24px !important;
    //   &:first-child {
    //     background-color: $grey-10;
    //     border-color: $grey-10;
    //     color: $text-light-01;
    //     text-transform: capitalize;
    //   }
    //   + button.magic-button-container.primary{
    //     background-color: #005cb9;
    //     margin-left: 24px;
    //   }
    // }
  }
  // > .magic-input,
  // > .list-container {
  //   //padding: 0 24px;
  //   display: flex;
  //   box-sizing: border-box;
  //   width: 100%;
  //   flex-direction: column;
  //   .top-row{
  //     width: 100%;
  //     display: flex;
  //     min-height: 38px;
  //     background: #ffffff;
  //     padding: 10px 18px;
  //     box-sizing: border-box;
  //     margin-bottom: 4px;
  //     line-height: 16px;
  //     position: relative;
  //     @extend %ptype-5-strong;
  //     >div{
  //       margin-right: 12px;
  //     }
  //   }
  // }
  > .magic-input {
    margin-bottom: 4px;
  }
  > .list-container {
    // max-height: 44vh;
    // min-height: 44vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: $main-padding;
    padding-bottom: 20px;
    padding-left: $main-padding;
    // margin: 0 $modal-Xmargin;
    // position: relative;
    // display: flex;
    // justify-content: flex-start;
    >div.selection-indicator{
      margin-top:auto;
    }
    // &.empty{
    //   justify-content: flex-end;
    // }
    .main-item{
      width: 100%;
      display: flex;
      min-height: 48px;
      background: $white;
      border: 1px solid $grey-40;
      padding: 14px 18px;
      box-sizing: border-box;
      margin-bottom: 4px;
      line-height: 16px;
      position: relative;
      &::after {
        content: "";
        background: transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAGFBMVEVMTExZWVlmZmZzc3OZmZnl5eXy8vL///9ahuc/AAAARElEQVR42mNgJwAGQsGoAjZUJqYCZhYYi4UJqwJWBhaoPCO6CQgVCHmEAlQVcHmEAjQVMHmEAjQVQHn84cA2aONiVAEAy48bh8aX3D8AAAAASUVORK5CYII=") 0 0 no-repeat;
        width: 32px;
        height: 32px;
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 5px;
        transform-origin: center;
        transform: rotate(180deg);
      }
      svg {
        margin-right: 12px;
        cursor: pointer;
        stroke:none;
      }
      &.selected {
        user-select: none;
        &::after {
          transform: rotate(0deg);
        }
      }
    }
    .sub-item {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 43px);
      min-height: 70px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      transform: translateX(43px);
      padding: 10px 12px;
      margin-bottom: 4px;
      flex-wrap: wrap;
      &.left{
        transform: none;
        width: 100%;

      }
      .start-items{
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        svg {
          margin-right: 12px;
          margin-left: 12px;
          cursor: pointer;
          stroke:none;
        }

        .discount-columns {
          display: flex;
          align-items: center;

          .discount-item {
            margin-right: 8px;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: 12px;

            .grey-line {
              margin: 0px 12px 0px 12px;
            }
            &.amount {
              background-color: #f3f3f3;
            }
          }
        }
      }
      .rectangle {
        background: #edf5ff;
        border-radius: 8px;
        overflow: hidden;
        width: 46px;
        height: 46px;
        display: flex;
        margin-right: 12px;
        justify-content: center;
        align-items: center;
        img {
          object-fit: cover;
          max-width: 46px;
          max-height: 46px;
        }
        @media (max-width: 400px) {
          display: none;
        }
      }
      .company {
        display: flex;
        flex-direction: column;
        color: $text-light-01;
        margin-right: auto;
        strong {
          @extend %ptype-5-strong;
        }
        span {
          @extend %ptype-3;
        }
      }
      .tags {
        display: flex;
        align-items: center;
        // margin-left: auto;
        @media (max-width: 400px) {
          margin-top: 10px;
        }
        .tag {
          background-color: $grey-10;
          padding: 7px 8px 8px;
          border-radius: 4px;
          @extend %ptype-3;
          color: $text-light-01;
          ~ .tag {
            margin-left: 6px;
          }
        }
      }

      &.discount {
        display: flex;
        width: 100%;
        transform: inherit;
        //background: blue;
      }

      &.selected {
        background-color: #edf5ff;
        .rectangle {
          background-color: #d0e2ff;
        }
        .tags {
          .tag {
            background-color: $primary-4;
            color: #0f62fe;
          }
        }
      }
    }
  }
}

// the good one!
.central-modal-overlay.fixed-modal{
  padding: $modal-top-margin $modal-Xmargin 112px;
  @include max-S {
    padding: 0 0 112px;
  }
  &.no-footer{
    padding: $modal-top-margin $modal-Xmargin 0px;
    @include max-S {
      padding: 0;
    }
  }

  .list-modal-wrapper{
    max-width: 100%;
    height: 100%;
    border: 1px solid #F3F3F3;
    box-shadow:0px 4px 4px rgba(0,0,0,.25);
    border-radius: 4px 4px 0 0;
    @include max-S {
      border-radius: 0;
    }
    .list-container {
      max-height: none;
      min-height: auto;
      margin-bottom: 50px;

      // height: calc( 100% - 200px );
      @include max-S {
        // height: calc( 100% - 218px );
        .top-row{
          padding-right: 0.25rem;
          padding-left: 0.25rem;
        }
      }
      // div.selection-indicator {
      //   margin-top: auto;
      //   position:fixed;
      //   z-index:1;
      //   left:0;
      //   right:0;
      //   bottom:112px;
      //   top:auto;
      //   box-sizing: border-box;
      //   padding-right: $main-padding;
      //   padding-left: $main-padding;
      //   width:100%;
      //   transform: translate(0,0,);
      //   @include max-S {
      //     padding-right: 1.5rem;
      //     padding-left: 1.5rem;
      //   }
      // }
    }
    .big-modal-footer{
      position:fixed;
      z-index:1;
      left:0;
      right:0;
      bottom:0;
      top:auto;
      // .selection-indicator{
      //   box-sizing: border-box;
      //   padding-right:$main-padding;
      //   padding-left: $main-padding;
      //   height: 56px;
      //   background-color: #ECF5FF;
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   color:#333333;
      //   margin: 0 $modal-Xmargin;
      //   @include ptype-5-strong;
      //   .see-all{
      //     color:#005CB9;
      //     @extend %ptype-5;
      //     border:0;
      //     background-color: transparent;
      //     padding:0;
      //     outline: none;
      //     cursor: pointer;
      //   }
      // }
    // .buttons{
    //   padding-right: $modal-Xmargin;
    //   padding-left: $modal-Xmargin;
    //   @include max-S {
    //     padding-right: 1.5rem;
    //     padding-left: 1.5rem;
    //   }
    // }
  }
  }


}