

@mixin cateogry-label {
font-family: Open Sans;
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 120%;
/* or 17px */

display: flex;
align-items: center;
letter-spacing: -0.02em;
cursor: pointer;
}

@mixin categ-category {
font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 120%;
/* identical to box height, or 17px */
letter-spacing: -0.02em;
cursor: pointer;
}
.category-filter-wrapper{
  margin-top: 16px;
  &.no-top-margin{
    margin-top: 0;
  }
  .categ-category{
    @include categ-category;
    height: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-left: var(--indentValue);
    &:first-child, &:nth-child(2) {
      margin-left: 0px;
    }
    &.selected {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #F3F3F3;
    }
    .icon-title {
      display: flex;
      align-items: center;
    }
    .categ-label{
      margin-right: 48px; ///very bad woraround, but I couldn't figur out flex
      
        }
    svg {
      margin-right: 8px;
      flex:auto !important;
    }
  }
  .categ-item{
    @include cateogry-label;
    margin-top: 8px;
    line-height: 24px;
    display: flex;
    margin-bottom: 0.5rem;
    margin-left: var(--indentValue);
    &.selected{
      font-weight: 700;
    }
    &.spaced {
      justify-content: space-between;
    }
    .icon {
      display: inline-grid;
    }
    .empty-icon {
      width: 28px;
    }
    svg {
      margin-right: 8px;
    }
  }
}