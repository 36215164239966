@use "~@synergic/core/style/scss/colors";

$wizzardPadding :40px;
.wizzard-form-wrapper{
    box-sizing: border-box;
    width: 100%;
    max-width: 840px;
    background-color: $white;
    // .make-it-a-table{
    //     display: grid;
    //     grid-template-columns: 10fr repeat(2, 3fr) repeat(2, 4fr);

    //     >span{
    //         @include ptype-5-strong;
    //         padding-left: 0.5rem;
    //     }
    //     >div{
    //         border-top: 1px solid #e6e6e6;
    //         border-bottom: 1px solid #e6e6e6;
    //         padding: 0.5rem;
    //         margin-top: 0.5rem;
    //     }
    //     >div:nth-child(5n){
    //         border-right: 1px solid #e6e6e6;
    //     }
    //     >div:nth-child(5n+1){
    //         border-left: 1px solid #e6e6e6;
    //     }
    //     .magic-input{
    //         width: auto ;
    //     }
    //     .bar{

    //         border-left: 1px solid $text-light-03;
    //         padding-left: 12px;
    //         margin-left: 12px;
    //       }
    //       .remove-container{
    //         >button{
    //             margin-left:auto !important;
    //         } 
    //       }
    // }
    .form-body{
        // margin: 0 $wizzardPadding;
    }

    > .header{
        @include etype-2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin: $wizzardPadding;
        // margin-bottom: 1.5rem;
        > .close{
            border: 0;
            background-color: transparent;
            padding: 0;
            width: 32px;
            height: 32px;
            outline: none;
        }
    }
    .wizzard-footer{
        display: flex;
        height: 112px;
        background-color: colors.$gray-10;
        align-items: center;
        padding: 0 1.5rem;
        margin-top: 1.5rem;
        button{
            flex:0 1 220px;
          }
        // justify-content: space-between;
        // > button{
        //     height: 3rem;
        //     border:0;
        //     border-radius: 0;
        //     padding: 14px 24px !important;
        //     color:#212121;
        //     @media (max-width:460px) {
        //         padding: 14px 14px !important;
        //     }
        //     &:disabled{
        //         background-color: #f3f3f3;
        //         color:#212121;
        //         opacity: 0.25;
        //     }
        //     &.primary{
        //         background-color:#005cb9 ;
        //         color:#ffffff;
        //         &:disabled{
        //             background-color: #f3f3f3;
        //             color:#212121;
        //             opacity: 0.25;
        //         }
        //     }
        // }
    }
    .middle-section{
        display: flex;
        flex: 1;
        margin: 0 1.5rem;
        align-items: center;
        @media (max-width:460px) {
            margin: 0 0.5rem;
        }
        >div{
            flex:1;
        }
        >div+div{
            margin-left: 0.5rem;
        }
    }
    .step-name {
        margin-bottom: 5px;

        &.old{
            font-weight: bold;
            color: $text-light-01;
        }
        &.new{
            color: colors.$gray-60;
        }
    }
    .step-underline {
        height: 5px;
        border-radius: 2.5px;
        &.old{
            background-color: $text-light-01;
        }
        &.new{
            background-color: colors.$gray-50;
        }
    }
}