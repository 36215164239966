
$basePadding : 0.25rem;
$baseMargin : 0.25rem;

$spaceValues: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20;

@each $space in $spaceValues{
    // margins
    .m#{$space}{
        margin: calc(#{$space} * #{$baseMargin});
    }
    .mt#{$space}{
        margin-top: calc(#{$space} * #{$baseMargin});
    }
    .mb#{$space}{
        margin-bottom: calc(#{$space} * #{$baseMargin});
    }
    .ml#{$space}{
        margin-left: calc(#{$space} * #{$baseMargin});
    }
    .mr#{$space}{
        margin-right: calc(#{$space} * #{$baseMargin});
    }
    .my#{$space}{
        margin-top: calc(#{$space} * #{$baseMargin});
        margin-bottom: calc(#{$space} * #{$baseMargin});
    }
    .mx#{$space}{
        margin-left: calc(#{$space} * #{$baseMargin});
        margin-right: calc(#{$space} * #{$baseMargin});
    }

    // paddings
    .p#{$space}{
        padding: calc(#{$space} * #{$basePadding});
    }
    .pt#{$space}{
        padding-top: calc(#{$space} * #{$basePadding});
    }
    .pb#{$space}{
        padding-bottom: calc(#{$space} * #{$basePadding});
    }
    .pl#{$space}{
        padding-left: calc(#{$space} * #{$basePadding});
    }
    .pr#{$space}{
        padding-right: calc(#{$space} * #{$basePadding});
    }
    .py#{$space}{
        padding-top: calc(#{$space} * #{$basePadding});
        padding-bottom: calc(#{$space} * #{$basePadding});
    }
    .px#{$space}{
        padding-left: calc(#{$space} * #{$basePadding});
        padding-right: calc(#{$space} * #{$basePadding});
    }

    //flexGap
    .gap#{$space}{
        gap: calc(#{$space} * #{$basePadding});
    }

    .w#{$space}{
        width: calc(#{$space} * 4 * #{$basePadding});
    }

    .ht#{$space}{
        height: calc(#{$space} * 2 * #{$basePadding});
    }
    .h#{$space}{
        height: calc(#{$space} * 2 * #{$basePadding});
    }

}
