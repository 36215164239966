@mixin wrapper-spacing {
    max-width: 1520px;
    margin: 0 auto;
    padding: 0 5rem;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin-bottom: 5rem;

    @include only-S {
        padding: 0 3rem;
    }
    @include only-XS {
        $padding: 1rem;
        > div + div {
            margin-top: $padding;
        }
        .col {
            &.left,
            &.right {
                width: calc(100vw - 2 * #{$padding});
                > div + div {
                    margin-top: $padding;
                }
            }
        }
        flex-direction: column;
        padding: $padding;
    }
}

@mixin responsive-etype-6 {
    @include etype-6;

    @include max-MD {
        @include etype-5;
    }
    @include max-S {
        @include etype-3;
    }
}
@mixin responsive-ptype-6 {
    @include ptype-6;

    @include max-S {
        @include ptype-5;
    }
}

@mixin responsive-card-padding4rem {
    padding: 4rem;

    @include max-S {
        padding: 1.5rem;
    }
}

@mixin guest-page-spacing {
    padding: 5rem;
    padding-top: 2rem;

    max-width: 1550px;
    margin-left: auto;
    margin-right: auto;
    // grid-column: 1/3 ;
    @include max-LG {
        padding: 3rem;
        padding-top: 2rem;
    }
    @include only-S {
        padding: 3.5rem;
        padding-top: 2rem;
    }
    @include only-XS {
        padding: 2rem;
    }
}

@mixin greyLabel {
  background-color: #f3f3f3;
  padding: 12px 24px;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

@mixin icon-box-40{
    cursor: pointer;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 40px;
    height: 40px;

}

@mixin action-color-hover {
    &:hover {
        svg{
            stroke-width: 1.5px;
        }
        p{
            color: $black;
        }
    }

}

@mixin responsiveFormGrid($field-width:300px) {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(#{$field-width},1fr));
    grid-gap: 24px;
    .check-box-switch{
      margin-top: 24px;
    }
    .full-line{
      grid-column: -1/1;
    }
    .first-col{
      grid-column: 1/2;
    }
  }

@mixin article-text-container(){
    background: white;
    @include responsive-card-padding4rem;
    // width: 40rem;

    .SubTitle1 {
        @extend %ptype-5-strong;
        color: $text-light-01;

        margin-bottom: 1.5rem;
    }
    .Text1 {
        @include responsive-ptype-6;
        color: $text-light-01;
    }
    p {
        @include responsive-ptype-6;
        color: $text-light-01;
    }

    .SubTitle2 {
        @extend %ptype-5-strong;
        color: $text-light-01;
        margin-bottom: 1.5rem;
    }
    .Text2 {
        @include responsive-ptype-6;
        color: $text-light-01;
    }
    .Link {
        display: flex;
        color:$button-prime;
        margin-left:5px;
        @extend %ptype-5-strong;
        text-decoration: none;
      }
}