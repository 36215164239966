$switchHeight: 24px;
$switchWidth: calc(2 * #{$switchHeight});
.sorinsContainer {
  padding: 0;
  background-color: $grey-50;
}

.wrapContainer {
  background-color: transparent;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  @include max-S {
    margin-bottom: 1.5rem;
  }
  .col {
    display: flex;
    &.left {
      width: 48%;
      flex: 1 1 48%;
      padding: 0 5rem 0 0;
      flex-direction: column;
      box-sizing: border-box;
      @include max-LG {
        width: 50%;
        flex: 1 1 50%;
      }
      @include max-MD {
        width: 52%;
        flex: 1 1 52%;
        padding: 0 1.75rem 0 0;
      }
      @include max-S {
        width: 100%;
        flex: 1 1 100%;
        padding: 0;
        margin-bottom: 2.5rem;
      }

      p {
        @extend %ptype-6;
        margin: 0 0 1.5rem;
        @include max-MD {
          font-size: 16px !important;
        }
      }
      a {
        text-decoration: none;
      }
      .spacer {
        width: 100%;
        height: 4.5rem;
        display: flex;
        background-color: transparent;
      }
      .content {
        &1 {
          background-color: $white;
          display: flex;
          flex-direction: column;
          padding: 2.5rem 2.5rem 0;
          a {
            color: $button-prime;
          }
        }
        &2 {
          background-color: $white;
          display: flex;
          flex-direction: row;
          padding: 1.5rem 2.5rem;
          justify-content: flex-start;
          align-items: center;
          color: $text-light-01;
          @extend %ptype-5-strong;
          font-weight: 700;
          @include max-S {
            padding: 1.5rem;
          }
          img {
            margin-right: 2.5rem;
            @include max-S {
              max-width: 90px;
              margin-right: 1.5rem;
            }
          }
          p {
            margin: 0;
            @include max-S {
              font-size: 12px;
              font-weight: 700;
            }
          }
        }
      }

      .buttonContainerMainCTA {
        width: 80px;
        height: 80px;
        margin-bottom: -40px !important;
        display: flex;
        align-self: flex-end;
        position: relative;
        z-index: 1;
      }
    }
    &.right {
      display: flex;
      background-color: $white;
      width: 52%;
      flex: 1 1 52%;
      flex-direction: column;
      @include max-LG {
        width: 50%;
        flex: 1 1 50%;
      }
      @include max-MD {
        width: 48%;
        flex: 1 1 48%;
      }
      @include max-S {
        width: 100%;
        flex: 1 1 100%;
      }

      .slider-holder {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
  @include wrapper-spacing;
}

.fullContainer {
  @include wrapper-spacing;
  @include max-S {
    margin-bottom: 1.5rem;
  }
  .article {
    background-color: $white;
  }
  // width:100%;
  // max-width: 1520px;
  // margin:0 auto;
  margin-bottom: 5rem;
  // display: flex;
  flex-direction: column;
}

.slider-holder {
  position: relative;
  display: block;
}

.selling {
  padding: 5rem 0;
  background: #333333;
  .inner-wrap {
    @include wrapper-spacing;
    display: flex;
    flex-direction: column;
  }
  .loadingBar {
    height: 8px;
    border-radius: 4px;
    background: #828282;
    > div {
      background: #ea0029;
      height: 8px;
      border-radius: 4px;
      width: 22%;
    }
  }
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    height: 480px;
    @include max-S {
      height: calc(100vw - 2rem);
    }
  }
  .relative-holder {
    position: relative;
    .btn-bg-button-prime {
      position: absolute;
      z-index: 1;
      right: 80px;
      transform: translateY(-50%);
      @include max-MD {
        right: 40px;
      }
      @include max-S {
        right: 1rem;
      }
    }
  }
}

.lastSection {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5rem 0;
  margin: 0;
  @include max-MD {
    padding: 2.5rem 0;
    min-height: auto;
  }
  @include max-S {
    flex-direction: column;
  }

  > .inner {
    @include wrapper-spacing;
    display: flex;
    flex-direction: row;
    margin-bottom: 0;

    .bg-white {
      min-width: 400px;
      @include max-MD {
        min-width: 150px;
        width: 40%;
        flex: 1 1 40%;
        margin-right: 2.5rem;
      }
      @include max-S {
        min-width: 150px;
        width: 100% !important;
        flex: 1 1 100%;
        margin-right: 0;
        max-width: 100%;
      }
      + .bg-white {
        margin-top: 20vh;
        @include max-MD {
          width: 60%;
          flex: 1 1 60%;
          margin-right: 0;
        }
        @include max-S {
          margin-top: 3.5rem;
          min-width: 150px;
          width: 100%;
          flex: 1 1 100%;
          margin-right: 0;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
