
.input-tags{
    @include ptype-3;
    .top-label{
        display: flex;
        padding-bottom: .5rem;
        color: $darkesttype;
        min-height: 16px;
        
        .tag-error-text{
            color: $alert-critical;
        }
    }
    .tags-container{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        box-sizing: border-box;
        height: fit-content;
        min-height: 40px;
        padding: .5rem;
        background-color: $whitebkg;
        gap: .75rem;
        box-sizing: border-box;
        border: 1.5px solid #F4F4F4;
        border-radius: 4px;
        padding: .5rem;

        &:empty{
            height:  40px;
            min-height: 40px; 
        }

        &.tag-error{
            border: 1px solid $alert-critical;
        }
        .add-button{
            padding: .5rem;
            border-radius: .25rem;
            background: $bluebkg;
            cursor: pointer;
        }
        .input-tag{
            background: $bluebkg;
            border: none;
            padding-left: .5rem;
            color: $detailstype;

            display: inline-flex;
            box-sizing: border-box;
            height: 2rem;
            padding: 0 .5rem;
            border-radius: .25rem;
            // @include ptype-4;
            
            &:focus{
                outline: none;
            }
        }
    }
}
