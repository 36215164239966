// @import 'style/colors';
// @import 'style/typography';
// @import 'style/_media';

.product-form-wrapper {

    .wrap-sections {
        .white-form-card-wrapper {
            .magic-input {
                textarea {
                    min-height: 100px;
                }
            }
        }
    }

    @include max-S{
        padding:0 1.5rem;
    }

    $innerPadding: 1rem;
    $height: 7rem;

    .image {
        margin-left: 0.25rem;
        width: calc(#{$height} - 2 * #{$innerPadding});
        height: calc(#{$height} - 2 * #{$innerPadding});
        // background-color: $placeholderBlue;
        object-fit: contain;
      }

    .page-section{
        // display: column;
        columns: 2;
        column-gap: 1.5rem;
        margin-bottom: 2.5rem;
        // gap: 1.5rem;
        @include max-S{
            columns:unset;
            margin-bottom: 1.5rem;
        }
        & > div{
            break-inside: avoid;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

           @include max-S{
                grid-gap: 0;
                gap: 0;
                margin-bottom: 0;
                > div{
                    grid-gap:0;
                    gap: 0;
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
    .inline-sections{
        display:  flex;
        align-items: flex-end;
        gap: 1.5rem;
        >div{
            flex: 1;
        }
    }

    .product-form-category-section{
        .item-row{
            margin-bottom: 1.5rem;
            &:last-child{
                margin-bottom: 0;
            }
            .category-row{
                display: flex;
                padding: .5rem;

                .value-box{
                    padding: .5rem;
                    @include ptype-5;
                }
            }
        }
    }

    // Detalii technice
    .technical-details-section{
        .technical-detail-row{
            margin-bottom: .25rem;
            display: flex;
            color: $text-light-03;
            @include ptype-4;


            .action-icon{
                border: none;
                stroke: $text-light-03;
                justify-content: flex-end;
                background: inherit;
                &:hover {
                  border: none;
                  stroke-width: 1.5;
                }
            }

            &.visible{
                color: $text-light-01;

                .action-icon{
                    stroke: $text-light-01;
                }
            }
        }

        .label-box{
            padding: 1rem;
            width: 50%;
            display: flex;
            align-items: center;
            // background-color: $gray-40; //TODO:SE: debug - not working
        }
        .value-box{
            padding: 1rem;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .action-icon{
                right: 0;
            }
        }
    }

    .product-form-currency-box{
        border-left: 1px solid $selectionbkg;
        padding-left: .75rem;
        padding-right: .5rem;
    }

    .icon-nostroke{
        stroke: none;
    }

}
