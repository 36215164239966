@mixin article-section-type-products{
  
  display: flex;
  flex-direction: column;
  .checkboxes{
    display: flex;
    flex-wrap: wrap;
    border-bottom: solid 1px #f3f3f3;
    width:calc(100% + 48px);
    margin-left: -24px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: -18px;
    .check-box-switch{
      margin-right: 24px;
      margin-bottom: 17px;
      .ptype-3{
        @extend %ptype-4;
      }
    }
  }
  .products-listed{
    margin-top: 28px;
    display: flex;
    flex-wrap: wrap;
    gap: 49px;
    
    .prod-item{
      width: 80px;
      height: 80px;
      display: flex;
      position: relative;
      .del{
        position: absolute;
        z-index: 1;
        top:0;
        right:0;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border:0;
        outline: none;
        background-color: $white;
        padding:0;
        cursor: pointer;
        transform: translate(12px,-12px);
        svg{
          width: 24px;
          height: 24px;
          stroke: none;
        }
        &:hover{
          svg{
            path{
              fill:#313131;
            }
          }
        }
      }
      .img-wrap{
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow:hidden;
        background: #EDF5FF;
        border-radius: 8px;
        overflow:hidden;
        border:solid 1px #EDF5FF;
        box-sizing: border-box;
        >img{
          object-fit: cover;
          max-width: 80px;
          max-height: 80px;
          display: flex;
        }
      }
      input{
        padding: 8px;
        height: 28px;
        border: 1px solid $grey-10;
        width: 70px;
        color: $button-prime;
        @include ptype-5-strong;
      }
      .quantity-unit-label{
        padding-right: 8px;
        color: $text-light-02;
        @include ptype-5-strong;
      }
      input, .quantity-unit-label{
        position: absolute;
        right: -32px;
        top: 50%;
        transform: translateY(-50%);
      }

    }
    .add-btn{
      background: #FFFFFF;
      border: 1px dashed #B3B3B3;
      box-sizing: border-box;
      border-radius: 8px;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        width: 16px;
        height: 16px;
        stroke: none;
      }
    }
  }
}

.add-section-btn-wrapper{
  display: flex;
  align-items: center;
  background: #ECF5FF;
  border: 2px dashed #005CB9;
  box-sizing: border-box;
  min-height: 72px;
  padding:0 24px;
  flex-wrap: wrap;
  box-sizing: border-box;
  .title{
    margin-right: auto;
    white-space: nowrap;
    @media (max-width:1560px) {
      display: none;
    }
    @media (max-width:767px) {
      margin-top: 24px;
      display: flex;
      width: 100%;
    }
  }
  .buttonContainerMainCTA{
    background-color: transparent;
    color:#005CB9;
    border:0;
    outline: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    white-space: nowrap !important;
    @media (max-width:767px) {
      padding-left: 0 !important;
    }
    &:last-child{
      padding-right: 0 !important;
    }
    &:hover,
    &:focus{
      background-color: transparent;
      color:#005CB9;
    }
    >svg{
      max-width: 16px;
      max-height: 16px;
      path{
        stroke: #005CB9;
      }
    }
  }
}

.drop-area > div ~ div{
  margin-top: 1.5rem;
}

.one-section-wrapper{
  background-color: $white;
  .title{
    display: flex;
    min-height: 72px;
    border-bottom: solid 1px #F3F3F3;
    align-items: center;
    padding:11px 29px;
    box-sizing: border-box;
    > svg{
      margin-right:13px;
    }
    span {
      margin-right: auto;
    }
  }
  + .one-section-wrapper{
    margin-top: 1.5rem;
  }
  .section-type{
    display: flex;
    box-sizing: border-box;
    &.type{
      &-heroImage,
      &-thumbnailImage,
      &-image,
      &-textImage,
      &-text,
      &-subtitle,
      &-video,
      &-products,
      &-callToAction{
        padding:36px 24px 24px;
      }
      &-thumbnailImage{
        .uploader, .img-holder{
          //background-color: blueviolet;
          //position:relative;
          @include circle(120px);
          background-color: #86C2FF;
          .img-wrap{
            // object-fit: contain;
            // overflow: hidden;
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 50%;
            background-color:#f3f3f3;

            > img{
              width: 120px;
              height: 120px;
              object-fit: cover;
            }
          }


        }
      }
      &-textImage{
        display: flex;
        .quill{
          display: flex;
          flex-direction: column;
          width: calc(100% - 200px);
          margin-right: 40px;
        }
      }
      &-text,
      &-subtitle{
        display: flex;
        .quill{
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;
        }
      }
      &-video{
        display: flex;
        width: 100%;
        flex-direction: column;
        .line{
          display: flex;
          box-sizing: border-box;
          width: 100%;
          input{
            border: solid 1px #adadad;
          }
          .btn-video{
            border:solid 1px #005cb9;
            background-color: #005cb9;
            color:$white;
            border-radius: 0;
            padding:0 24px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            cursor: pointer;
            &.del{
              background-color: $white;
              color:$text-light-01;
              border-color: #adadad;
            }
          }
        }
        video{
          background-color: #f9f9f9;
        }
      }
      &-products{
        @include article-section-type-products;
      }
      &-sectionMarker{
        gap: 24px;
        flex-direction: column;
        .custom-select-wrapper{
          // flex:1
        }
        input{
          // height: 64px;
          // margin-right: 16px;
        }
      }
      &-callToAction{
        display: flex;
        flex-direction: column;
        .magic-select .selectContainer.grey {
          background-color:$white;
          border: 1px solid #ccc;
          height: 48px;
          label{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color:$text-light-01;
          }
          select{
            > option{
              padding: 10px;
              box-sizing: border-box;
            }
          }
        }
      }
    }

    input{
      &:not([type="file"]){
        width: 100%;
        // height: 54px;
        display: flex;
        border:0;
        outline: none;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
        color:$text-light-01;
        padding:16px 24px;
        box-sizing: border-box;
        &.video-link{
          border: solid 1px #adadad;
        }
      }
      &[type="file"]{
        width: 100%;
        height: 90px;
        display: flex;
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        z-index:3;
        opacity: 0;
        cursor: pointer;
      }
    }

    .uploader{
      width: 160px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 8px;
      border: 1px dashed #B3B3B3;
      position: relative;
      &::before,
      &::after{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
      }
      &::before{
        content:'';
        z-index: 1;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #333333;
      }
      &::after{
        content:attr(data-text);
        z-index:2;
        color:$white;
      }
    }

    .img-holder{
      width: auto;
      margin-right: 28px;
      position: relative;
      .img-wrap{
        width: 160px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 8px;
        background-color:#f3f3f3;

        > img{
          width: 160px;
          height: 90px;
          object-fit: cover;
        }
      }
      .del{
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        top:0;
        right: 0;
        transform: translate(12px,-12px);
        padding: 4px;
        box-sizing: border-box;
        border: 0;
        outline: none;
        cursor: pointer;
        svg{
          .stroke-button-prime & {
            stroke:none;
          }
        }

      }
    }
  }

  // >.magic-button-container{
  //   background-color: $white;
  //   padding: 0 !important;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   >svg{
  //     max-width: 28px;
  //     max-height: 28px;
  //     font-size: 28px;
  //     path{
  //       stroke: $text-light-01;
  //     }
  //   }

  // }

  .smaple-holder{
    display: flex;
    border-top: solid 1px #F3F3F3;
    display: flex;
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    margin-top: 1.5rem;
    padding: 1.5rem 1.5rem 0;
    box-sizing: border-box;
    margin-bottom: 1rem;
    &.full{
      padding-top: 0;
      margin-bottom: 0;
      img{
        max-width: 240px;
      }
    }
    img{
      max-width: 160px;
    }

    + .double-select{
      border-top:0;
      margin-top: 0;
      padding: 0 1.5rem;
    }
  }

  .double-select{
    border-top: solid 1px #F3F3F3;
    display: flex;
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    margin-top: 1.5rem;
    padding: 1.5rem 1.5rem 0;
    box-sizing: border-box;
    @media (max-width:600px) {
      flex-direction: column;
    }
    .select-type{
      width: calc(50% - 0.75rem);
      min-width: 240px;
      max-width: 240px;
      @media (max-width:600px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
      }
      + .select-type{
        margin-left: 1.5rem;
        @media (max-width:600px) {
          margin-left: 0;
          margin-bottom: 0;
        }
      }

      >label{
        font-family: IBM Plex Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 8px;
        display: flex;
      }

      .crs__control{
        height: 48px;
      }
      .crs__value-container{
        height: 46px;
        padding: 0 8px;
        overflow: unset;
        >div{
          margin: 0;
          padding: 0;
          height: 46px;
          line-height: 46px;
        }
        .crs__single-value{
          height: 46px;
          line-height: 46px;
          margin: 0;
          top: 0;
          transform: translateY(0);
          + div{
            height: 46px;
            margin: 0;
            padding: 0;
            top:0;
            display: flex;
          }
        }
      }
      .crs__input{
        height: 46px;
        input:not([type="file"]){
          height: 46px;
          line-height: 46px;
        }
      }
      .crs__indicator-separator{
        display: none;
      }
    }
  }
}


.section-type.type-image{
  .double-select{
    .percent{
      position: relative;
      &::after{
        content:attr(data-percent);
        position: absolute;
        top:1px;
        right: 15px;
        line-height: 46px;
        height: 45px;
        pointer-events: none;
      }
      .crs__indicator{
        display: none;
      }
    }
  }
}

.article-editor-page{
  @media (max-width:767px) {
    padding: 24px 24px 0;

    .flex.equalColl{
      width: 100%;
      flex-direction: column;
      >.mr6{
        margin-right: 0 !important;
      }
      .bg-white.p6{
        margin-top: 24px;
        box-sizing: border-box;
        width: 100%;
        flex:1 1 100% !important;
      }
    }

  }
}

.sticky-buttons{
  @media (min-width:1200px) {
    position: fixed;
    z-index: 10;
    right: 2rem;
  }

}