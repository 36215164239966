.pill{
    .pill-content{
        display: inline-flex;
        min-height: 1.5rem;
        align-items: center;
        box-sizing: border-box;
        border-radius: 2rem;
        text-align: center;
        white-space: nowrap;
    }
    .small{
        padding: .25rem .75rem;
        min-height: 1.5rem;
    }
    .medium{
        padding: .5rem 1rem;
        min-height: 2rem;
    }
    .large{
        padding: .5rem 1rem;
        min-height: 3rem;
    }
    .pill-icon{
        display: flex;
        align-items: center;
        margin-right: .5rem;
    }
}
