
.switch-container-k3{
  $switchHeight: 1rem;
  $switchWidth: calc(1.5 * #{$switchHeight});
  $buttonSize: calc(#{$switchHeight * 0.4});


  position: relative;
  height: $switchHeight;
  width: $switchWidth;
  border: 1px solid; 
  border-radius: calc(0.5 * #{$switchHeight});
  box-sizing: border-box;
  cursor: pointer;
  .switch {
    position: absolute;
    top: 50%;
    height: $buttonSize;
    width: $buttonSize;
    border: 1px solid; 
    border-radius: calc((#{$switchHeight * 0.5}) / 2);
    transform: translateY(-50%);
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &.sw-active{
    border-color: $blue1;
    background-color: $bluebkg;
    .switch{
      left: calc(#{$switchHeight * 0.25});
      border-color: $blue1;
      background-color: $whitebkg;
    }
  }
  &.sw-inactive{
    border-color: $blue1;
    background-color: none;
    .switch{
      left: calc(#{$switchWidth} - #{$buttonSize} - calc(#{$switchHeight * 0.5}));
      border-color: $blue1;
      background-color: $whitebkg;
    }
  }
  &.sw-disabled{
    border-color: $inactive;
    background-color: none;
    cursor: default;
    .switch{
      border-color: $inactive;
      background-color: $whitebkg;
    }
  }
}
