.sys-confirm-action{
    .modal-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    >span{
        margin-top: 32px;
        margin-bottom: 40px;
        text-align: center;
    }
    >div{
        @media (max-width: 500px) {
            flex-direction: column;
            gap: 1.5rem;
        }
    }
}
}