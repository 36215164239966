.invoice-view-wrapper {
  $m767: 767px;
  @media (max-width: $m767) {
    padding: 0 1.5rem;
  }

  .achitat {
    background-color: #24a148;
    padding: 25px;
    .title {
      display: flex;
      flex-direction: row;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 140%;
      color: #ffffff;
    }
    .content {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 120%;
      margin-top: 9px;
      color: #ffffff;
    }
  }

  .payment-status-box {
    stroke: $white;
    fill: $white;
    color: $white;
    padding: 0.5rem;
    padding-right: 0.75rem;
    @media(max-width: 1100px) {
      margin-top: 1.5rem;
    }

    .title {
      padding-right: 0.25rem;
      padding-left: 0.75rem;
      display: inline-flex;
      align-items: center;
      @include ptype-5-strong;
    }
    .details {
      margin: 0.25rem 0;
      display: inline-flex;
      align-items: center;
      @include ptype-5;
    }

    &.with-border {
      padding-right: 0.5rem;

      .title {
        padding: 0 0.75rem;
      }
      .details {
        padding: 0 0.75rem;
        border-left: 1px solid $white;
      }
    }
  }

  .summary-subtitle {
    display: flex;
    gap: 1.5rem;
  }

  .orderSummaryGrid {
    display: grid;
    grid-template-columns: repeat(4, minmax(10rem, 1fr));
    grid-template-rows: repeat(2, auto);
    margin-top: 1.5rem;
    gap: 1.5rem;
    @include max-LG {
      grid-template-columns: repeat(3, minmax(10rem, 1fr));
      .emptyDiv {
        display: none;
      }
    }
    @include max-MD {
      grid-template-columns: repeat(2, minmax(10rem, 1fr));
    }
    @include max-S {
      grid-template-columns: repeat(1, minmax(10rem, 1fr));
    }
  }

  .infoCard {
    display: flex;
    border: 1px solid $grey-40;
    height: 4rem;
    flex: 0 1 20rem;
    align-items: center;
    padding-left: 0.5rem;
    img {
      margin-right: 0.75rem;
    }
    & > div {
      margin-right: 0.5rem;
    }
  }
  .dld-buttons {
    display: flex;
    gap: 0.5rem;
  }
  .page-section {
    display: flex;
    columns: 2;
    column-gap: 1.5rem;
    // gap: 1.5rem;
    margin-bottom: 2.5rem;
    // gap: 1.5rem;
    @media (max-width: 1100px) {
      display: block;
      columns: unset;
      margin-bottom: 1.5rem;
    }

    .card {
      flex: 1;
      .left-card {
        margin-right: auto;
        .details {
          max-width: 300px;
          @include etype-1;
        }
      }
    }

    .left-box {
      flex: 1;
    }

    .value-box {
      display: flex;
      flex-direction: column;
      background-color: #f3f3f3;
      padding: 0.25rem 1rem;
      width: 200px;
      justify-content: center;
    }

    & > div {
      display: flex;
      flex: auto;
      break-inside: avoid;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      &.right-box {
        max-width: 420px;
        @media (max-width: 1100px) {
          max-width: unset;
        }
        .payment-method {
          border: 1px solid #e6e6e6;
          padding: 16px;
        }
      }

      @media (max-width: $m767) {
        grid-gap: 0;
        gap: 0;
        margin-bottom: 0;
        > div {
          grid-gap: 0;
          gap: 0;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  .cardItem {
    background-color: $white;
    //TODO> max width
    width: 100%;
    // max-width: 400px;
    overflow-wrap: break-word;
    border: 1px solid $grey-40;
    padding: 1rem;
    box-sizing: border-box;
    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
    }
    .title {
      margin: 0.25rem 0;
    }
  }

  .action-icon {
    border: none;
    justify-content: flex-end;
    background: inherit;
    &:hover {
      border: none;
      stroke-width: 1.5;
    }
  }

  .icon-nostroke {
    stroke: none;
  }
  .center-text {
    display: inline-flex;
    align-items: center;
  }
  .credit-level-bar {
    display: flex;
    position: relative;

    div {
      height: 4px;
      position: absolute;
    }
    .bar-base {
      background-color: $blue-60;
      width: 100%;
    }
    .bar-current-level {
      background-color: $green-40;
    }
    .bar-estimated-level {
      background-color: $button-prime;
    }
    .custom-width {
      width: var(--width, 100);
    }
  }
}
