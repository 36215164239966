// @import "../abstracts/colors";
@import 'style/colors';
.check-box-wrapper {
  stroke: $text-light-01;
  &.selected {
    svg {
      fill: $text-light-01;
      stroke: $white;
    }
  }
}