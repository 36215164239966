
%ptype-1 {
  /* ptype-1 */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 100%;
  /* or 8px */
  // letter-spacing: 0.04em;
}

%ptype-2 {
  /* ptype-2 */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 120%;
  /* identical to box height, or 12px */
  // letter-spacing: -0.02em;
  text-transform: uppercase;
}

%ptype-3 {
  /* ptype-3 */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
}

%ptype-4 {
  /* ptype-4 */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */
  // letter-spacing: 0.01em;
}

%ptype-5 {
  /* ptype-5 */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
}


%ptype-6 {
  /* $ptype-6 */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */
}

%ptype-7 {
  /* $ptype-7 */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
}

%ptype-8 {
  /* $ptype-8 */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 120%;
  /* identical to box height, or 34px */

  // letter-spacing: -0.01em;
}

%ptype-9 {
  /* $ptype-9 */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */

  // letter-spacing: -0.02em;
}

%etype-1 {
  /* etype-1 */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  // letter-spacing: 0.02em;
}


%etype-2 {
  /* etype-2 */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  /* or 28px */
}
%etype-3 {
  /* etype-3 */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */
}
%etype-4 {
  /* etype-4 */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;
  /* identical to box height, or 34px */
}
%etype-5 {
  /* etype-5 */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */
}
%etype-6 {
  /* etype-6 */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 120%;
  /* identical to box height, or 46px */
}

%etype-7 {
  /* etype-7 */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 120%;
  /* identical to box height, or 53px */
}
%etype-8 {
  /* etype-8 */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 120%;
  /* identical to box height, or 53px */
}

%ptype-3-strong {
  /* ptype-3-strong */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
}


%ptype-2-reg {
  /* ptype-2-reg */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 120%;
  /* identical to box height, or 12px */

  // letter-spacing: -0.02em;
}
%ptype-4-bold {
  /* ptype-4-bold */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  // letter-spacing: -0.02em;
}
%ptype-4-price-strike {
  /* ptype-4-price-strike */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 120%;
  /* or 12px */

  // letter-spacing: -0.02em;
  text-decoration-line: line-through;
  text-transform: uppercase;
}
%ptype-5-strike {
  /* ptype-5-strike */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  text-decoration-line: line-through;
}
%ptype-5-strike-normal {
  /* ptype-5-strike */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  text-decoration-line: line-through;
}
%ptype-4-price {
  /* ptype-4-price */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 120%;
  /* identical to box height, or 12px */

  // letter-spacing: -0.02em;
  text-transform: uppercase;
}
%ptype-1-specialdeal {
  /* ptype-1-specialdeal */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 100%;
  /* identical to box height, or 8px */

  text-decoration-line: line-through;
}
%ptype-5 {
  /* ptype-5 Strong */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
}

%ptype-5-strong {
  /* ptype-5 Strong */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
}



@mixin ptype-1 {
  /* ptype-1 */
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 100%;
  /* or 8px */
  // letter-spacing: 0.04em;
}


@mixin ptype-2 {
  /* ptype-2 */
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 120%;
  /* identical to box height, or 12px */
  // letter-spacing: -0.02em;
  text-transform: uppercase;
}


@mixin ptype-3 {
  /* ptype-3 */
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
}


@mixin ptype-4 {
  /* ptype-4 */
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */
  // letter-spacing: 0.01em;
}


@mixin ptype-5 {
  /* ptype-5 */
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
}



@mixin ptype-6 {
  /* $ptype-6 */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */
}


@mixin ptype-7 {
  /* $ptype-7 */

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
}


@mixin ptype-8 {
  /* $ptype-8 */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 120%;
  /* identical to box height, or 34px */

  // letter-spacing: -0.01em;
}


@mixin ptype-9 {
  /* $ptype-9 */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */

  // letter-spacing: -0.02em;
}

@mixin etype-1 {
  /* etype-1 */
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  // letter-spacing: 0.02em;
}




@mixin etype-2 {
  /* etype-2 */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  /* or 28px */
}

@mixin etype-3 {
  /* etype-3 */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */
}

@mixin etype-4 {
  /* etype-4 */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;
  /* identical to box height, or 34px */
}

@mixin etype-5 {
  /* etype-5 */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */
}

@mixin etype-6 {
  /* etype-6 */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 120%;
  /* identical to box height, or 46px */
}


@mixin etype-7 {
  /* etype-7 */
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 120%;
  /* identical to box height, or 53px */
}

@mixin etype-8 {
  /* etype-8 */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 120%;
  /* identical to box height, or 53px */
}


@mixin ptype-3-strong {
  /* ptype-3-strong */

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
}



@mixin ptype-2-reg {
  /* ptype-2-reg */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 120%;
  /* identical to box height, or 12px */

  // letter-spacing: -0.02em;
}

@mixin ptype-4-bold {
  /* ptype-4-bold */

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  // letter-spacing: -0.02em;
}

@mixin ptype-4-price-strike {
  /* ptype-4-price-strike */

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 120%;
  /* or 12px */

  // letter-spacing: -0.02em;
  text-decoration-line: line-through;
  text-transform: uppercase;
}

@mixin ptype-5-strike {
  /* ptype-5-strike */

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  text-decoration-line: line-through;
}
@mixin ptype-5-strike-normal {
  /* ptype-5-strike */

  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  text-decoration-line: line-through;
}
@mixin ptype-4-price {
  /* ptype-4-price */

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 120%;
  /* identical to box height, or 12px */

  // letter-spacing: -0.02em;
  text-transform: uppercase;
}

@mixin ptype-1-specialdeal {
  /* ptype-1-specialdeal */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 100%;
  /* identical to box height, or 8px */

  text-decoration-line: line-through;
}

@mixin ptype-5 {
  /* ptype-5 Strong */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
}


@mixin ptype-5-strong {
  /* ptype-5 Strong */

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
}

