$mobile:768px;

.request-list,
.badge-list,
.volume-list,
.compano-list,
.job-list,
.product-list,
.offers-list,
.documents-list,
.discount-table,
.volume-form{
  display: block;
  .title{
    @media (max-width:$mobile) {
      margin-bottom:calc(3*0.25rem);
    }
  }
  .table-wrapper{
    overflow-y: visible;
    overflow-x: auto;
    padding-bottom: 4px;
    width:calc(100% + 12*0.25rem);
    margin-left:calc(-6*0.25rem);
    min-height: 486px;
    @media (max-width:$mobile) {
      min-height: calc(100vh - 420px);
    }

    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent // #f3f3f3;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $hover-button-secondary;
      border-radius: 0.25rem;
    }
    >div:not(.multy-select-modal){
      min-width: 1200px;
      padding: 0 1.5rem;
    }
  }
}

.volume-list{
  .table-wrapper{
    >div:not(.multy-select-modal){
      min-width:860px;
    }
  }
}

.badge-list{
  .table-wrapper{
    >div:not(.multy-select-modal){
      min-width:500px;
    }
  }
}

.documents-list .table-wrapper{
  .cell-6{
    min-width: 200px;
  }
}

.request-list .table-wrapper{
  .cell-1{
    min-width: 245px;
  }
  .cell-2{
    min-width: 160px;
  }
}


.top-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(6 * 0.25rem);
  &.px0{
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width:$mobile) {
    flex-wrap: wrap;
    margin: 0;
    padding:calc(3 * 0.25rem);
    >button{
      margin-left: auto;
    }
    &.p0-15{
      padding:0 1.5rem;
      +.p0-15{
        padding:0 1.5rem;
        &.title{
          margin-bottom: calc(3*0.25rem);
        }
      }
    }
  }
}

.add-account{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem;

  .last-block{
    .select-overwrites-1{
      height: 40px;
      .crs__control {
        min-height: 38px;
        border-radius: 0;
        box-sizing: border-box;
      }
    }
  }
  
  @media (max-width:800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2;
    grid-gap: 0;
    > .bg-white{
      margin-right:1.5rem;
      margin-bottom: 1.5rem;
      + .bg-white{
        margin-right: 0;
        margin-bottom: 1.5rem;
      }
    }

    .last-block{
      grid-row: 2 / 2;
      grid-column: 1 / end;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap:0;
      .bg-white{
        margin-right:1.5rem;
        + .bg-white{
          margin-right: 0;
        }
      }
    }
  }
  @media (max-width:767px) {
    padding: 0 1.5rem;
  }

  @media (max-width:600px) {
  
    display: flex;
    flex-direction: column;
    .bg-white{
      margin-right:0;
      margin-bottom: 1.5rem;
      + .bg-white{
        margin-right: 0;
        margin-bottom: 1.5rem;
      }
    }
    .last-block{
      display: flex;
      flex-direction: column;
      .bg-white{
        margin-right:0;
        + .bg-white{
          margin-right: 0;
        }
      }
    }
  }
}