$brakepoints : (
    XS: 376px,
    S: 768px,
    MD: 1024px,
    LG: 1440px,
    XLG: 1680px
);

$width-XS: 376px;
$width-S: 768px;
$width-MD: 1024px;
$width-LG: 1440px;
$width-XLG: 1680px;

///max
// MAX-375
@mixin max-XS {
  @media (max-width: #{$width-XS - 1px}) {
    @content;
  }
}
@mixin max-xS {
  @media (max-width: #{$width-XS - 1px}) {
    @content;
  }
}
// MAX-767
@mixin max-S {
  @media (max-width: #{$width-S - 1px}) {
    @content;
  }
}

// MAX-1023
@mixin max-MD {
  @media (max-width: #{$width-MD - 1px}) {
    @content;
  }
}

// MAX-1439
@mixin max-LG {
  @media (max-width: #{$width-LG - 1px}) {
    @content;
  }
}

// MAX-1679
@mixin max-XLG {
  @media (max-width: #{$width-XLG - 1px}) {
    @content;
  }
}

///min
@mixin min-XS {
  @media (min-width: #{$width-XS}) {
    @content;
  }
}

@mixin min-S {
  @media (min-width: #{$width-S}) {
    @content;
  }
}

@mixin min-MD {
  @media (min-width: #{$width-MD}) {
    @content;
  }
}

@mixin min-LG {
  @media (min-width: #{$width-LG}) {
    @content;
  }
}

@mixin min-XLG {
  @media (min-width: #{$width-XLG}) {
    @content;
  }
}

///exact

@mixin only-XS {
  @media (max-width: #{$width-S - 1px}) {
    @content;
  }
}
@mixin only-S {
  @media (min-width: #{$width-S}) and (max-width: #{$width-MD - 1px}) {
    @content;
  }
}
@mixin only-MD {
  @media (min-width: #{$width-MD}) and (max-width: #{$width-LG - 1px}) {
    @content;
  }
}
@mixin only-LG {
  @media (min-width: #{$width-LG}) and (max-width: #{$width-XLG - 1px}) {
    @content;
  }
}
@mixin only-XLG {
  @media (min-width: #{$width-XLG}) {
    @content;
  }
}

:export {
  brakepoints: $width-LG;
}
