.comment-section{  
    .comment-list{
        gap: 1rem;
        flex-wrap: wrap;
        display: flex;
    }
    .comment-item{
        width: 100%;
        position: relative;
    }
    .comment-index{
        margin-bottom: .5rem;
        @include ptype-3; 
    }
    .remove-btn-container{
        position: absolute; 
        right: 0;
        @include ptype-3; 
    }
    .date-container{
        padding-top: .5rem;
        color: $text-light-02;
        @include ptype-3; 
    }

    .remove-btn{
        background: none;
        border: none;
    }
    .comment-container{
        display: flex;
        padding-top: .75rem;
        padding: 1rem;
        border: 1px solid $grey-40;
        box-sizing: border-box;
        background: $grey-10;
        @include ptype-4; 
    }
    .attachment-container{
        display: flex;
        padding: 0;
        padding-top: .5rem;
        color: $text-light-01;
        cursor: pointer;
        
        .img-wrap {
            width: 2.25rem;
            height: 2.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 8px;
            background-color: #EDF5FF;
            > img {
              width: 2.25rem;
              height: 2.25rem;
              object-fit: cover;
            }
            .extension-div{
              @extend %ptype-2;
              color:#0F62FE;
              text-transform: uppercase;
            }
          }

        .details-container{
            padding: .25rem;
            margin-left: .25rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title{
                @include ptype-2; 
            }
            .subtitle{
                @include ptype-1; 
        }
        }
    }
}
