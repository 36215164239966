
.cell-list{
    display: flex;
    align-items: flex-start;
    // justify-content: center;
    flex-direction: column;
    white-space: nowrap;
    // padding: 1.25rem;
    // min-height: 5rem; //TODO: fix height or min?
    // width: 100%;
    background-color: $whitebkg;
    @include ptype0;


    .more-item-counter{
        background: $selectionbkg;
        border-radius: .25rem;
        padding: .25rem .5rem;
        margin-top: .25rem;
        @include ptype-2;

    }
}
