.comp-price-container{
    background: $bluebkg;
    display: inline-flex;
    align-items: center;
    padding: .5rem;
    color: $blue-1;
    border-radius: .25rem;
    @include ptype-4;
    .currency-container{
        padding-right: 0;
        padding-left: .25rem;
        &.prefix{
            padding-right: .25rem;
            padding-left: 0;
        }
    }
    .trend-container{
        padding: .25rem;
        background-color: $alert-critical;
        color: $purewhite;
        border-radius: .25rem;
        margin-left: .5rem;
        @include ptype-3;
    }
}
