@import "src/style/colors";
@import "src/style/typography";
@import "src/style/mixins";

$parentHeight: 64px;

.page-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 64px;
  bottom: 0;
  z-index: 2;
  background: rgba(162, 169, 176, 0.4);
  backdrop-filter: blur(4px);
}

.dual-bar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logout-item {
  display: flex;
  min-height: 64px;
  max-width: 64px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.magic-sidebar {
  background: #4D5358;
  flex-direction: column;
  justify-content: flex-start;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 64px;
  overflow-y: hidden;
  z-index: 10;


  &.open {
    width: 320px;
    overflow-y: auto;
  }

  &.closed {
    width: 64px;
    &:hover {
      width: 320px;
      overflow-y: auto;

      .menu-children {
        display: block;
      }
    }

    .menu-children {
      display: none;
    }
  }

  a {
    text-decoration: none;
  }
}

.menu-item {
  width: 320px;
  .menu-parent {
    position: relative;
    display: flex;
    align-items: center;
    min-height: $parentHeight;
    padding-right: 24px;
    cursor: pointer;

    &:hover {
      background: $cool-gray-60;
    }

    &.active {
      background: $cool-gray-80;

      &:hover {
        background: $cool-gray-60;
      }

      &::before {
        content: '';
        width: 6px;
        height: 40px;
        background: $cool-gray-30;
        position: absolute;
        left: 0;
        border-radius: 0 120px 120px 0;
      }
    }

    &__content {
      flex: 1;
      display: flex;
      align-items: center;
    }

    &__icon {
      width: 32px;
      height: 32px;
      transition: transform 0.5s ease-in-out;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    .icon-ct {
      display: flex;
      flex: 0 0 64px;
      align-items: center;
      justify-content: center;
      height: 100%;
      max-width: 80px;
    }

    label {
      color: white;
    }
  }



  .menu-children {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    transition-duration: 0.5s;
    transition-property: max-height;

    svg {
      stroke: white;
    }

    a {
      text-decoration: none;
      color: white;
    }

    .active {
      position: relative;

      &::before {
        content: '';
        width: 6px;
        height: 40px;
        background: $cool-gray-10;
        position: absolute;
        left: 0;
        top: 11px;
        border-radius: 0 120px 120px 0;
      }

      a {
        font-weight: bold;
      }
    }

    .menu-child {
      background-color: $cool-gray-80;
      display: flex;
      align-items: center;
      gap: 10px;
      padding-left: 30px;
      height: $parentHeight;
      cursor: pointer;

      &:hover {
        background: $cool-gray-60;
      }

    }
  }

  &--opened {
    .menu-children {
      max-height: 100rem;
      transition: max-height 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }

    .menu-parent {
      &__icon {
        transform: rotate(180deg);
      }
    }
  }
}

.menu-search-wrap {
  position: relative;

  .drop-list {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: 10;
    top: 90%;
    left: 0;
    right: 0;
    background-color: white;
    padding: 1rem;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    transition: all 0.4s ease-out;
    max-height: 0;
    border-radius: 8px;
    filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.25));

    &.opened {
      opacity: 1;
      pointer-events: all;
      max-height: 75vh;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}