.link-info-box{

  &.attached-icon{
      stroke: $darkesttype;
  }
  &.expired-icon{
      stroke: $alert-critical;
  }

  .link-info-box-row{
      display: flex;
      justify-content: space-between;
      border: 1px solid $grey-10;
      padding: .5rem;
      align-items: center;

      .value-box{
          flex: auto;
          color: $text-light-01;
          padding: .5rem;
          @include ptype-5;
      }
      .action-icon{
          border: none;
          justify-content: flex-end;
          background: inherit;
          &:hover {
              border: none;
              stroke-width: 1.5;
          }
      }
  }
}