// @import 'style/colors';

@mixin button-generalV2 {
  cursor: pointer;
  position: relative;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  &.strong{
    font-weight: bold;
  }
  .loading-indicator{
    position: absolute;
    /* left: 0; */
    transform: scale(0.4) ;
    backdrop-filter: blur(5px);
    cursor: inherit;
  }
  .badge {
    color: $text-light-01;
    position: absolute;
    top: 25%;
    right: 25%;
    border-radius: 4px;
    min-width: 1rem;
    background: $white;
    text-align: center;
    @include ptype-3;
    font-weight: 700;
    border: 1px solid $text-light-02;
  }
  &:hover:not(.selected) {
    stroke-width: 1.5;
  }
  &.selected {
    .badge {
      border: none;
    }
  }
}
%btnPrimaryV2 {
  @include button-generalV2;
  background-color: $button-prime;
  color: $grey-10;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  @extend %etype-1;
  text-overflow: clip;
  white-space: nowrap;
  stroke: $text-light-01;
  &:hover {
    background-color: $hover-button-prime;
  }
  &:active {
    background-color: $click-button-prime;
  }
  &.disabled {
    background-color: $text-light-03;
  }
}

@mixin btnPrimaryOutlineV2 {
  @include button-generalV2;
  color: $button-prime;
  background-color: $white;
  border: 1px solid $button-prime;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  @extend %etype-1;
  text-overflow: clip;
  stroke: $text-light-01;
  white-space: nowrap;

  &:hover {
    color: $hover-button-prime;
    border: 1px solid $hover-button-prime;
    stroke-width: 1.5;
  }
  &:active {
    color: $click-button-prime;
    border: 1px solid $click-button-prime;
  }
}

%btnSecondaryV2 {
  @include button-generalV2;
  background-color: $button-secondary;
  color: $grey-10;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  stroke: $text-light-01;

  &:hover {
    background-color: $hover-button-secondary;
  }
  &:active {
    background-color: $click-button-secondary;
  }
}

// %btnFocus {
//   @include button-generalV2;
//   background-color: $button-focus;
//   color: $grey-10;
//   text-align: center;
//   cursor: pointer;
//   transition: all 0.2s ease-out;
//   stroke: $text-light-01;

//   &:hover {
//     background-color: $hover-button-focus;
//     stroke: $white;
//   }
//   &:active {
//     background-color: $click-button-focus;
//     stroke: $white;
//   }
// }

%btnGreyV2 {
  @include button-generalV2;
  background-color: $grey-40;
  color: $grey-10;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  stroke: $text-light-01;

  &:hover {
    background-color: lighten($grey-40, 5%);
  }
  &:active {
    background-color: darken($grey-40, 10%);
  }
}

%btnGreenV2 {
  @include button-generalV2;
  // width: 100%;
  background-color: $green-40;
  color: $white;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  stroke: $text-light-01;

  @extend %etype-1;
  &:hover {
    background-color: lighten($green-40, 10%);
  }
  &:active {
    background-color: darken($green-40, 10%);
  }
}

.magic-button-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  &.redAlert {
    background-color: $alert-red;
    //stroke: $button-focus;
    //color: white;
    @extend %btnPrimaryV2;
    stroke: $white;
    &.statusFont {
      @include ptype-5;
    }
    &:hover{
      background-color: darken($alert-red, 10%);
      // font-weight: bold;
    }
    &.disabled:hover {
      //cursor: unset;
      cursor: not-allowed;
      background-color: $text-light-03;
    }
  }
  &.yellowAlert {
    background-color: $alert-danger;
    //stroke: $button-focus;
    //color: white;
    @extend %btnPrimaryV2;
    stroke: $white;
    &.statusFont {
      @include ptype-5;
    }
    &:hover{
      background-color: darken($alert-danger, 10%);
      // font-weight: bold;
    }
    &.disabled:hover {
      //cursor: unset;
      cursor: not-allowed;
      background-color: $text-light-03;
    }
  }
  &.deep_purple {
    color: $white;
    border: 1px solid $white;
    background-color: $purple;
    @extend %etype-1;
    &:hover{
      cursor: pointer;
      background-color: lighten($purple, 10%);
      // font-weight: bold;
    }
  }
  &.primary {

    justify-content: center;
    @extend %btnPrimaryV2;
    stroke: $white;

    &.disabled {
      user-select: none;
      cursor: not-allowed;
    }

    .label {
      // padding-left: 1rem; //temp fix
      flex: 1;
    }
  }

  &.secondary {
    @extend %btnSecondaryV2;
    stroke: $text-light-01;

    .label {
      // padding-left: 1rem; //temp fix
      flex: 1;
    }
  }

  & .green {
    @extend %btnGreenV2;
    stroke: $text-light-01;

    .label {
      padding-left:1rem; //temp fix
      flex: 1;
    }
  }

  & .grey {
    justify-content: center;
    @extend %btnGreyV2;
    stroke: $text-light-01;
    &.blue {
      stroke: $button-prime;
      color: $button-prime;
      @include etype-1;
      stroke-width: 1.5;
    }
    &.red {
      stroke: $button-focus;
    }

    .label {
      // padding-left: 1rem; //temp fix
      flex: 1;
    }
    // &:hover:not(.selected){
    //   stroke-width: 1.5;
    // }
    &.selected {
      background-color: $button-prime;
      stroke: $white;
    }
  }

  &.simple {
    @include button-generalV2;
    // cursor: pointer;
    stroke: #333;
    &:hover:not(.selected) {
      stroke-width: 1.5;
    }
    &.selected {
      background-color: $button-prime;
      &.active-green {
        background-color: $green-40;
      }
      stroke: $white;
    }
  }
  &.white {
    @include button-generalV2;
    // cursor: pointer;
    stroke: #333;
    color: #333;
    border: none !important;
    background-color: $white !important;
    &:hover:not(.selected) {
      // background-color: $text-light-03 !important;
      font-weight: bold;
    }
  }
  &.black {
    @extend %btnPrimaryV2;
    // cursor: pointer;
    stroke: $white;
    color: $white;
    // border: none !important;
    background-color: $text-light-01 !important;
    &:hover:not(.selected) {
      background-color: lighten($text-light-01, 10%) !important;
      // font-weight: bold;
    }
  }

  .click {
    cursor: pointer;
  }
  // &.buttonWhite { //not used?
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   border: 2px solid #333333;
  //   box-sizing: border-box;
  //   padding: 2rem;
  //   color: black;
  //   @extend %btnGreyV2;
  // }


  &.outline {
    @include btnPrimaryOutlineV2;
    background-color: white;
    stroke: $button-prime;

    .label {
      // padding-left: 1rem; //temp fix
      flex: 1;
    }
    &:hover{
      background-color: white;
      color: lighten($button-prime, 15%);
      border-color: lighten($button-prime, 15%);
    }
    &:active{
      background-color: white;
    }
    &.grey {
      border-color: $grey-10;
      background-color: $white;
      color: $text-light-01;
      .label {
        // padding-left: 0;
        white-space: nowrap;
        padding: 0 1.5rem;
      }
      &:hover {
        background-color: lighten($hover-button-secondary, 10%);
      }
      &:active {
        background-color: lighten($hover-button-secondary, 0%);
      }
    }
  }
  &.clear{
    position: relative;
    .loading-indicator{
      position: absolute;
      /* left: 0; */
      transform: scale(0.4) ;
      backdrop-filter: blur(3px);
      cursor: inherit;
    }
    &.clearStroke {
      stroke: none;
    }
    background-color: transparent;
    padding: 0 !important;
    &:hover {
    background-color: transparent;
    }
    &:active {
    background-color: transparent;
    }
  }
}
