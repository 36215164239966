.contact-preferences {
  &.role-form {
    .check-table-wrapper {
      &.count {
        &-4 {
          > div {
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5) {
              @include greyLabel;
            }
            &:nth-of-type(3) {
              color: $white;
              background-color: $all-good;
            }
            &:nth-of-type(4) {
              color: $white;
              background-color: $alert-danger;
            }
            &:nth-of-type(5) {
              color: $white;
              background-color: $alert-red;
            }
          }
        }
      }
    }
  }
  .check-table-wrapper {
    grid-template-columns: minmax(80px, 3fr) repeat(var(--option-count), 1fr);
    > div {
      &.check-box {
        padding: 4px 0;
        justify-content: center;
        width: 100%;
        margin-bottom: 8px;
        svg {
          display: flex;
          margin: auto !important;
          cursor: pointer;
        }
      }
    }
    &.count {
      &-2 {
        > div {
          &:nth-of-type(2),
          &:nth-of-type(3) {
            @include greyLabel;
          }
        }
      }
      &-3 {
        > div {
          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4) {
            @include greyLabel;
          }
        }
      }
      &-4 {
        > div {
          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4),
          &:nth-of-type(5) {
            @include greyLabel;
          }
        }
      }
      &-5 {
        > div {
          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4),
          &:nth-of-type(5),
          &:nth-of-type(6) {
            @include greyLabel;
          }
        }
      }
    }
  }
  .row-label {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
  .activity {
    padding: 8px 0;
    font-family: "Open Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #b3b3b3;
    margin: 0 auto 0 0;
    display: flex;
    text-transform: uppercase;

    // ~ .activity {
    //   padding-top: 16px;
    // }

    &.role {
      color: black;
      grid-column: 1 / end;
      background: $blue-60;
      width: 100%;
    }
  }
}

.login-preferences {
  .switch-container {
    background-color: #333333;
    &.active {
      background-color: #24a148;
    }
    + .ptype-3 {
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0.01em;
      text-align: left;
    }
  }
}

@import "product/productDocumentForm";
@import "product/productList";