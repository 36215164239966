
.dashboardContainer{
    stroke-width: 1.2px;
    display: grid;
    // background-color: aquamarine;
    grid-template-columns: 16fr 10fr;
    grid-template-areas:
        "header header  "
        "video profile"
        "company  settings "
        "thanx  tutorials "
        ;
    gap: 1.5rem;
    margin-bottom: 2.5rem;
    @include max-MD {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "header   header"
            "company  settings"
            "profile  profile"
            "video video"
            "thanx tutorials"
            ;
    }

    @include max-S {
        padding:1.5rem 1.5rem 0;
        // grid-template-columns: 1fr 1fr;
        // grid-template-areas:
        // "header   header"
        // "company  company"
        // "settings settings"
        // "active   relation"
        // "invoice final"
        // "alerts   alerts"
        // "profile profile";
        display: flex;
        flex-direction: column;
    }
    .header{
        grid-area: header;
        padding-left: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 555px) {
            padding: 1.5rem;
        }
        >svg{
            margin-right: 5rem;
            @media (max-width: 555px) {
                display: none;
            }
        }
    }
    .video {
        box-sizing: border-box;
        padding: 1.5rem;
        grid-area: video;
        //height: 350px;
        //min-height: 322px;
        .wrapper {
            // height: 90%;
            // width: 100%;
            // position: relative;
            position: relative;//taken from github react player issues
            padding-top: 56.25%;//https://github.com/CookPete/react-player/issues/145
            &.show {
                display: block;
            }
            &.hide {
                display: none;
            }
        }

        .player {
            position: absolute;
            top: 0;
            left: 0;
            //width: 100%;
            //height: 100%;
          }
        // .videoContainer {
        //     //margin-top: 1.5rem;
        //     position: absolute;
        //     top: 0;
        //     bottom: 0;
        //     right: 0;
        //     left: 0;
        //     width: auto;
        //     height: auto;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     &.show {
        //         display: block;
        //     }
        //     &.hide {
        //         display: none;
        //     }
        //     .react-player {
        //         padding-top: 56.25%; // Percentage ratio for 16:9
        //         position: relative; // Set to relative
        //       }
        //     .react-player > div {
        //         position: absolute; // Scaling will occur since parent is relative now
        //     }
        // }
    }

    .thanx {
        grid-area: thanx;
        box-sizing: border-box;
        min-height: 124px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        // padding-bottom: 2.5rem;
        // padding-top: 2.5rem;
        >.right {
            align-self: end;
        }
    }
    .tutorials {
        grid-area: tutorials;
        padding: 1.5rem;
        display: flex;
        gap: 3rem;
    }
    .company{
        grid-area: company;
        background-color: white;
        padding: 1.5rem;
        stroke: $text-light-01;
        display:flex;
        align-items: center;
        justify-content: space-between;
    }

    .settings{
        grid-area: settings;
        background-color: white;
        padding: 1.5rem;
        stroke: $text-light-01;
        display:flex;
        align-items: center;
        justify-content: space-between;
        .right{
            display: flex;
            gap: 1rem;
            div + div{
                margin-left: 1rem;
            }
        }
        // svg+svg{
        //     margin-left:1rem;
        // }
    }

    .active{
        grid-area: active;
        // background-color: white;
    }
    .relation{
        grid-area: relation;
        // background-color: white;
    }
    .invoice{
        grid-area: invoice;
        // background-color: white;
    }
    .final{
        grid-area: final;
        // background-color: white;
    }
    .alerts{
        grid-area: alerts;
        // background-color: white;
    }
    .profile{
        grid-area: profile;
        background-color: white;
    }

    }

.dashboardProfileCard{
    grid-area: profile;
    min-height: 540px;
    //height: 100%;
    background-color: white;
    display: flex;
    overflow:hidden;
    padding: 1.5rem;
    flex-direction: column;
    @include max-XLG {
        min-height: 510px;
    }
    @media (max-width: 1600px) {
        min-height: 480px;

    }
    // $width-MD: 1024px;
    // $width-LG: 1440px;
    // $width-XLG: 1680px;
    @media (max-width: 1520px){
        min-height: 460px;
    }
    @include max-LG {
        min-height: 450px;
    }
    @media (max-width: 1400px){
        min-height: 420px;
    }
    @media (max-width: 1300px){
        min-height: 400px;
    }
    @media (max-width: 1220px){
        min-height: 360px;
    }
    @media (max-width: 1160px){
        min-height: 340px;
    }
    .slider {
        width: auto;
        height: 100%;
        .slick-list{
            height: 100%;
            .slick-track{
                height: 100%;
            }
            .slick-slide{
                height: 100%;
                > div{
                    display: block !important;
                    height: 100%;
                    .item{
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        height: 100%;
                        img{
                            object-fit: cover;
                            //object-position: center;
                            width: 100%;
                            //height: 80%;//300px;
                            @media (max-width: 1300px){
                              //  height:70%
                            }
                            @media (max-width: 1220px){
                                //height:65%
                            }
                            @media (max-width: 1160px){
                                //height:60%
                            }
                            @include max-MD {
                               // height:80%
                            }
                            @include max-S {
                                //height: 70%
                            }
                        }
                        .actionButtons {
                            //position: absolute;
                            //bottom: 0;
                            //left: 0;
                            margin-top: 0.6rem;
                            display: flex;
                            flex-direction: column;
                            a {
                                text-decoration: none;
                                color: $text-light-01;
                                margin-top: 0.4rem;
                                >svg{
                                    stroke:  $text-light-01;
                                }
                                &:hover {
                                    color: darken($text-light-01, 15);
                                    >svg{
                                        stroke: darken($text-light-01, 15);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .slick-dots{
            //margin: 0 auto 40px 40px;
            //text-align: left;
            box-sizing: border-box;
            display: flex !important;
            justify-content: center;
            z-index: 10;
            //bottom: 0;
            width: 100%;
            //text-align: center;
            padding: 0;
            margin: 0 auto 15px;
            list-style: none;
            @include max-S{
                margin: 0 auto 5px;
            }
            ul{
                display: block;
                list-style-type: disc;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 40px;
            }
            >li{
                border:0;
                width:16px;
                height:4px;
                border-radius: 2px;
                background:#333333;
                margin:0 1px;
                transition: all 0.6s ease-in-out;
                &:hover{
                    background: lighten( #333333, 40);
                }
                button{
                    height: 0px;
                    padding: 0;
                    color: transparent;
                    opacity: 0;
                    cursor: pointer;
                }
                &.slick-active{
                    width:32px;

                }
            }

        }

        //background-color: brown;
    }
    // >div{
        // padding: 1.5rem;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // width: 100%;
        // //gap: 1.5rem;

    // }

}


.dashboardCard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    background-color: $white;
    stroke: $text-light-01;
    @media (max-width:767px) {
        height: auto !important;
    }
    div{
        .title{
        margin-bottom: 1.25rem;
        }
        .bottom{
            display: flex;
            justify-content: space-between;
        }
    }
    &.primary{
        background-color: $button-prime;
        color: $white;
        stroke: $white;
    }
    &.green{
        background-color: $green-40;
        stroke: $green-60;
        color: $white;
    }

}

.planning-calendar-dashboard{
    $cellHeight : 40px;
    display: grid;
    position: relative;
    grid-template-columns: repeat(7, $cellHeight);
    grid-template-rows: repeat(7,$cellHeight);
    .calendarDay {
        .day-tooltip {
            display: none;
        }
    &.show{//hover volt
        .day-tooltip {
            display: block;
            //min-height: 60px;
            top: 50%;//calc(100% + 10px);
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            padding: 1rem;
            border-radius: 6px;
            position: absolute;
            z-index: 1;
            background-color: $text-light-03;
            // &::after {
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     left: 50%;
            //     width: 0;
            //     height: 0;
            //     border: 13px solid transparent;
            //     border-bottom-color: $text-light-03;
            //     border-top: 0;
            //     margin-left: -13px;
            //     margin-top: -13px;
            // }
            .overdue {
                color: $red-1;
            }
            .pay {
                color: $green-60;
            }
            .covered {
                color: $purple-1;
            }
        }

    }
    }

    >div{
        // border-top:1px solid #E6E6E6;
        border-bottom:1px solid #E6E6E6;
        // border-right: 1px solid #E6E6E6;
        @extend %ptype-3;
        color: $text-light-01;
        &.green{
            background-color: $green-40;
            color: $white;
            @extend %ptype-3-strong;
            cursor: pointer;
        }
        &.purple {
            background-color: $purple-1;
            color: $white;
            @extend %ptype-3-strong;
            cursor: pointer;
        }
        &.red{
            background-color: $alert-red;
            color: $white;
            @extend %ptype-3-strong;
            cursor: pointer;
        }
        &.disabled{
            background-color: $grey-10;
            color: $text-light-03;
        }
        &.active{
            color: $button-prime;
            @extend %ptype-3-strong;
            .top{
                height: 2px;
            }
            .active{
                width:100%;
                height: 2px;
                background-color: $button-prime;
            }

        }
    }
    &>div + div {
        border-top:none;
        border-left: none;
        border-right: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
    }
    &>div:nth-child(7n+1){
        border-left:1px solid #E6E6E6;
    }

    .topDay{
        border-left: none !important;
        border-right: none;
        text-align: center;
        line-height: $cellHeight;
        @extend %ptype-3-strong;
    }

    // &>div:nth-child(-n+7){
    //     border-top:1px solid #E6E6E6;
    // }
}

.blueScrollBar{
    overflow: scroll;

    &::-webkit-scrollbar {
        width: 0;
        // max-width: 50px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: $grey-10;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $button-prime;
    }
}

.line1{
    height: 1px;
    width: 100%;
}

.line2{
    height: 2px;
    width: 100%;
}

.active-commands{
    width:100%;
    box-sizing: border-box;
    @media (max-width:767px) {
        width: 100%;
       padding:0 1.5rem;
    }
    .comands-wrap{
        display: flex;
        gap: 1.5rem;
        flex-wrap: wrap;
    }
}

.section-facturi{
    @media (max-width:767px) {
        width: 100%;
        padding:0 1.5rem;
        box-sizing: border-box;
        .comenzi{
            flex-wrap: wrap;
            flex-direction: column;
            margin: 0 auto;
            .w20{
                width: 100%;
                box-sizing: border-box;
            }
        }
       .limite{
           flex-wrap: wrap;
       }
    }
}

.info-cards-grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    grid-template-areas:
    "left middle right "
    ;
    @media (max-width: 1260px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "left  right"
            "middle middle"
            ;
    }
    @media (max-width: 560px) {
        //grid-template-columns: 1fr;
        grid-template-columns: minmax(0, 1fr);
        grid-template-areas:
            "left"
            "right"
            "middle"
            ;
    }
    .info-card-wrapper{
        display: flex;
        flex-direction: column;
        min-height: 320px;
        padding: 1.5rem;
        .content-short {
            max-width: 220px;
        }
        .content-long {
            max-width: 280px;;
        }
        .content-letItGrow {
            max-width: 450px;
        }
        .down {
            margin-top: auto;
            display: flex;
            align-items: center;
        }
    }
    .shopCard {
        grid-area: left
    }
    .offertCard {
        grid-area: middle
    }
    .newsCard {
        grid-area: right
    }
    .listsCard {
        grid-area: left;
        &.noAdmin {
            grid-area: unset;
            grid-column: 1 / span 3;
        }
    }
    .ordersCard {
        grid-area: middle
    }
    .invoicesCard {
        grid-area: right
    }

}