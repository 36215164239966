


.switch-container{
  $switchHeight: 20px;
  $switchWidth: calc(2 * #{$switchHeight});
  box-sizing: border-box;
  height: $switchHeight;
  width: $switchWidth;
  background-color: grey;
  border-radius: calc(0.5 * #{$switchHeight});
  // padding: 3px;
  position: relative;

  &.checkbox-type{
    width: calc(1.1 * #{$switchHeight});
    height: calc(1.1 * #{$switchHeight});
    border-radius: 50%;
    background-color: $white;
    &.active{
      background-color: $white;

    }
  }

  .switch {
    // box-sizing: border-box;
    position: absolute;
    height: calc(#{$switchHeight} - 6px);
    width: calc(#{$switchHeight} - 6px);
    background-color: $white;
    border-radius: calc((#{$switchHeight} - 6px) / 2);
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    // box-shadow: 0px 2px 1px -1px rgba(black, 20%), 0px 1px 1px 0px rgba(black, 14%), 0px 1px 3px 0px rgba(black, 12%);
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  &.active{
    background-color: $all-good;
    .switch{
      // background-color: yellow;
      left: calc(#{$switchWidth} - #{$switchHeight} + 2px);
    }
  }
}
