
.company-form-wrappwer {
  .grid-title-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width:767px) {
      padding:1.5rem;
    }
    .edit-buttons {
      display: flex;
    }
    .grid-title {
      @extend %etype-2;
    }
  }
  .item-title {
    @extend %etype-2;
  }

  >.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    //grid-template-rows: 1.18fr 1.3fr 1fr 1.6fr;
    grid-template-areas:
      "leftbar clasificare total"
      "leftbar contact restante"
      "leftbar filiala managers"
      "adress adress paymethod";
    gap: 24px;
    @media (max-width:991px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "leftbar total"
        "leftbar clasificare"
        "leftbar contact"
        "leftbar restante"
        "leftbar filiala"
        "leftbar managers"
        "adress paymethod";
    }
    @media (max-width:767px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "leftbar"
        "total"
        "clasificare"
        "contact"
        "restante"
        "filiala"
        "managers"
        "adress"
        "paymethod";
    }
    &.grid-page{
      @media (max-width:767px) {
        padding: 0 1.5rem 0;
      }
    }

    &.edit-mode {
      grid-template-areas:
        "leftbar clasificare managers"
        "leftbar contact paymethod"
        "adress adress .";
    }

    .grid-item-title {
      @extend %ptype-5-strong;
    }

    .leftbar {
      grid-row-start: 1;
      grid-row-end: 4;
      grid-area: "left-bar"; ///this replaces row-start coll-start etc...
      max-height: auto;
      // align-items: center;
      @media (max-width:767px) {
        grid-row-end: unset;
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      .adauga {
        @extend %ptype-5-strong;
        //there is no variable for this color
        color: #005cb9;
        cursor: pointer;
        justify-content: space-between;
      }
    }

    .relation-manager {
      margin-top: 1.5rem;

      align-content: center;

      .manager-card {
        display: flex;
        flex-grow: 1;
        background-color: $grey-50;
        margin-top: 25px;

        justify-content: space-between;
        align-items: center;
        border: 1px solid $grey-40;

        .name {
          display: flex;
          align-items: center;
          padding: 10px;
          .firstname {
            @extend %ptype-5;
            margin-left: 10px;
            margin-right: 10px;
          }
          .lastname {
            @extend %ptype-5;
          }
        }
        &.edit {
          background-color: $light-purewhite;
          border: none;
        }
      }
    }
    //areas
    .clasificare {
      grid-area: "clasificare";
    }
    .restante {
      grid-area: "restante";
    }
    .managers {
      grid-area: "managerii";
    }
    .filiala {
      grid-area: "managerii";
    }
    .paymethod {
      grid-area: "paymethod";
    }

    .adress,
    .cfa-wrapper {
      grid-area: adress; // fara ghilimele
      .title {
        display: flex;
        justify-content: space-between;
      }
      .adauga {
        @extend %ptype-5-strong;
        //there is no variable for this color
        color: #005cb9;
        cursor: pointer;
        justify-content: space-between;
      }
      .adresses {
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: $grey-50;
        margin-top: 16px;
        margin-bottom: 25px;

        .title {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
        }
        .content {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          color: $text-light-02;
        }
        .adress-icons {
          display: flex;
          cursor: pointer;
        }
      }
      .adresses-editmode {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $grey-40;
        margin-top: 20px;
        padding: 10px;

        .title {
          @extend %ptype-5-strong;
        }

        .editmode-icons {
          position: relative;
          align-content: center !important;
          border: none;
          display: flex;
          // justify-content: center;
        }
        .content-editmode {
          @extend %ptype-3;
        }
      }


      .relation-manager-card-wrapper + .relation-manager-card-wrapper{
        margin-top: 8px;
      }
    }
  }
  >.grid > div {
    //background-color: $white;
    padding: 1.5rem;
    .magic-input + .magic-input {
      margin-top: 1rem;
    }
    .check-box-switch {
      margin-top: 1rem;
    }
  }

  .progress-bar {
    background-color: $white;
    padding: 16px;
    margin-bottom: 24px;

    margin-top: 24px;
    .blue-bar {
      margin-top: 24px;
      background-color: $white;
    }
    .purple-bar {
      margin-top: 50px;
    }
  }
  .progresscomponent {
    padding: 0px 24px 24px 0px;
  }

  .progressbar-value {
    position: absolute;
    z-index: 15;
    color: $white;
    top: 50%;
    font-size: 16px;
    transform: translateY(-50%);
    padding-left: 20px;
  }

  .credit-limit {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @extend %ptype-5;
    >div{
      &:not(.credit-limit-value){
        margin-top: 24px;
      }
    }

    .credit-limit-value {
      display: flex;
      align-items:center;
      width:100%;
      max-width:284px;
      margin-top: 12px;
      padding:10px 20px;
      min-height: 40px;
      border: 1px solid $grey-40;
      text-align: left;
      box-sizing: border-box;
      @include max-S{
        max-width:100%;
      }
    }
    .limit-child {
      width: 320px;
      margin-right: 5%;
      @extend %ptype-5;
      @include max-S{
        margin-right: 0;
        width: 100%;
      }
    }
  }

  .sold-amount {
    display: flex;
    padding: 20px 0;
    .amount {
      margin-right: 30px;
      width: 320px;
      .amount-cube {
        border: 1px solid $grey-40;
        padding: 10px 20px;
        display: flex;
        align-items: center;
      }
    }
    .cube {
      width: 16px;
      height: 16px;

      &.purple {
        background-color: blueviolet;
      }
      &.ramas {
        background-color: $blue-50;
      }
    }
  }
  .retinut {
    margin-top: 20px;
  }
  .elibera-limita {
    display: flex;
    align-items: center;
    margin-right: 16px;
    padding: 20px;
    border: 1px solid $grey-40;
    .elibera-limita-text {
      @extend %ptype-5;
    }
  }

  .preferred-prices {
    // display: flex;
    // flex-wrap: wrap;
    grid-template-rows: auto;
    .preferred-card-dash {
      // display: flex;
      // flex-direction: column;
      // flex-basis: 400px;
      // flex-grow: 1;
      padding: 24px;
      color: $text-light-01;
      background-color: $white;
    }
  }


  .box-card-dash1 {
    display: flex;
    flex-direction: column;
    flex-basis: 400px;
    padding: 24px;
    color: $text-light-01;
    background-color: $white;
    .title {
      flex:0 0;
    }
    .text {
      width: 50%;
      display: flex;
      @include ptype-5-strong;
      flex: 1;
    }

    .unit {
      margin-top: auto;
    }

    &.restante {
      border: 1px solid $alert-red;
      background-color: #ffeaee !important;
      color: $alert-red;
      grid-area: restante !important;
      .text {
        background-color: #ffeaee;
        color: $alert-red;
      }
      .price {
        margin-top: auto;
        @include etype-5;
        display: flex;
        background-color: #ffeaee;
        color: $alert-red;
      }
    }
    &.total {
      background-color: $button-prime;
      grid-area: total;
      .text {
        background-color: $button-prime;
        color: $white;
      }
      .price {
        margin-top: auto;
        @include etype-5;
        color: $white;
        display: flex;
        background-color: $button-prime;
      }
      .title {
        background-color: $button-prime;
      }
    }

    &.achitat {
      background-color: $white;
      border: none;
    }
    &.recent {
      background: $white;
      border: 1px solid $green-40;
      box-sizing: border-box;
    }

    &.next {
      background: $white;
      border: 1px solid $purple-60;
      box-sizing: border-box;
    }

    .red-text {
      color: $alert-red;
      @include etype-5;
    }
    .purple-text {
      color: $purple-60;
      @include etype-5;
    }
    .black-text {
      color: $black;
      @include etype-5;
    }
    .green-text {
      color: $green-40;
      @include etype-5;
    }
  }

  .cards {
    &:empty{
      display: none;
    }
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
    min-height: 350px;
    justify-content: space-between;
    .card {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 50%;
    }
  }
}
