&.facturi,
&.comenzi{
  display: flex;
  flex-direction: column;
  @media (max-width:767px) {
    padding-top: 1rem;
    .heading-2,
    .invoice-section{
      width: calc(100% - 2rem);
      margin-left: 1rem;
      align-items: flex-start;
    }
  }
  .invoice-nr{
    white-space: nowrap;
    margin-bottom: 1rem;
    @media (max-width:767px) {
      margin-bottom: 0;
    }
  }
  .invoice-section{
    padding:1rem 1rem 0;
    .filterwrap{
      align-items: center;
    }
    @media (max-width:767px) {
      justify-content: flex-start;
      padding:1rem;
      .filterwrap{
        flex-wrap: wrap;
        align-items: flex-start;
      }
    }
    .invoice-filters{
      flex-wrap: wrap;
      .magic-input .inputContainer {
        margin-bottom: 1rem;
        margin-right: 0.5rem;
      }
    }
    .filterwrap .invoice-select{
      margin-left: 0;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
    @media (max-width:767px) {
      flex-direction: column;
    }
  }

  .table-wrapper{ 
    .inner{
      overflow-x: auto;
      &::after{
        content:'';
        height: 15px;
        width: 100%;
        background-color: white;
      }
      &::-webkit-scrollbar {
        height:8px;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
      background-color: #f3f3f3;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: #005cb9;
        border-radius: 4px;
        outline: none;
      }
    }

    .header-section,
    .content-line{
      min-width: 1375px;
    }
  }
}