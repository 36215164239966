@import "colors";
@import "typographyK3";
@import "typography";
@import "_media";
@import "spacing";
@import "mixins";
@import "utility";

@import "buttons";
@import "standalone";
@import "react-redux-toastr/src/styles/index";
@import "./toasterOverwrite.scss";
@import "./_media.scss";
@import "./componentsK3/index";
@import "./components";
@import "./pages";

html,
body {
  scroll-behavior: smooth;
}

#root {
  overflow-x: hidden;
}

.mr-1 {
  padding-right: 8px;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $grey-50;
}

*::-webkit-scrollbar-thumb {
  background-color: $hover-button-secondary;
  border-radius: 0.25rem;
  // outline: 1px solid slategrey;
}

$innerPadding: 1rem;

@mixin badge-long-card {
  $bannerHeight: 24px;
  height: $bannerHeight;
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  padding-left: calc(#{$innerPadding} * 0.7);
  padding-right: calc(#{$bannerHeight} / 2 + 0.5rem);
  @include ptype-3-strong;
  line-height: $bannerHeight;
  color: $white;
  clip-path: polygon(0% 0%, 100% 0, calc(100% - #{$bannerHeight} / 2) 50%, 100% 100%, 0% 100%);
  @extend %badgeBackgroundColor;
}

@mixin image-wrap-long-card($height) {
  // margin-right: 0.25rem;
  // min-width: calc(#{$height} - 2 * #{$innerPadding});
  // width: calc(#{$height} - 2 * #{$innerPadding});
  // height: calc(#{$height} - 2 * #{$innerPadding});
  // position: relative;
  // >img{
  //   position: absolute;
  //   z-index: 1;
  //   top:50%;
  //   left:50%;
  //   transform: translate(-50%,-50%);
  //   max-width: 100%;
  //   width: auto;
  //   height: auto;
  //   display: flex;
  //   max-height: 100%;
  // }
  // background-color: $white;
  width: $height;
  height: $height;
  margin-right: 1rem;
  // padding-top: 56.25%;
  display: flex;
  // overflow:hidden;
  // position: relative;
  // align-items: center;
  // justify-content: center;
  // text-align: center;
  > img {
    // position: absolute;
    // z-index: 1;
    // top:50%;
    // left:50%;
    // transform: translate(-50%,-50%);
    width: 100%;
    height: auto;
  }
}


$menuWidth: 4rem;
.app {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  height: 100vh;
}

.topMenu {
  height: 64px;
  position: relative;
  grid-column: 1/4;
  grid-row: 1/2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 32px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .logged-in-button {
    height: 100%;
    display: flex;
    background-color: $cool-gray-80;
    place-items: center;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
  }

  .menu-search-wrap {
    margin-left: 20px;

    .menu-search-ct {
      margin-left: 20px;
      height: 64px;
      align-items: center;
      display: flex;
    }
  }

  .left {
    display: flex;
    width: 64px;

    .hamburger {
      height: 64px;
      width: 64px;
      flex: 0 0 64px;
      white-space: break-spaces;
      background-color: $cool-gray-80;
      border: 0;
      outline: none;
      position: relative;
      transition: all 0.25s ease-out;
      cursor: pointer;

      &:active {
        background-color: #cdcdcd;
      }

      > span {
        background-color: white;
        width: 22px;
        height: 2px;
        display: block;
        position: absolute;
        pointer-events: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.25s ease-out;

        &::before,
        &::after {
          content: "";
          background-color: white;
          width: 22px;
          height: 2px;
          display: block;
          border-radius: 1px;
          transition: all 0.25s ease-out;
        }

        &::before {
          transform: translateY(-7px);
          transform-origin: 3px 4px;
        }

        &::after {
          transform: translateY(5px);
          transform-origin: 2px -3px;
        }
      }

      &.active {
        > span {
          background-color: transparent;

          &::before {
            transform: translateY(-7px) rotate(45deg);
            transform-origin: 3px 4px;
          }

          &::after {
            transform: translateY(5px) rotate(-45deg);
            transform-origin: 2px -3px;
          }
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    align-self: stretch;
  }

  .menuSearch {
    width: 30vw;
    border: none;
    padding: 0.75rem;
    @media (max-width: 1200px) {
      width: 40vw;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $gray-60;
      font-style: italic;
    }
  }

  .hideOnSmall {
    @include max-MD {
      display: none;
    }
  }
}

.menuItem {
  padding: 1rem;
  cursor: pointer;
  position: relative;

  &.gray {
    background-color: $grey-50;
  }

  &:after {
    content: attr(tooltip);
    /* here's the magic */
    position: absolute;
    /* vertically center */
    top: 50%;
    left: 100%;
    transform: translate(0em, -50%);
    /* move to right */
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    background: $text-light-01;
    clip-path: polygon(0.8rem 0%, 100% 0%, 100% 100%, 0.8rem 100%, 0% 50%);
    color: $white;
    @include ptype-4-price;
    // display: hidden;
    opacity: 0;
    transition: all 0.3s ease-out;
    z-index: 10;
  }

  &:hover:after {
    display: block;
    opacity: 1;
  }
}

.leftMenu {
  grid-column: 1/2;
  grid-row: 2/3;
  // background-color: red;
  // padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80px;
  @include max-MD {
    display: none;
  }
}

.leftOpenMenu {
  $topMenuPadding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  max-width: 27rem;
  padding: $topMenuPadding 0 0 0;
  background-color: white;
  z-index: 10;
  margin-top: $menuWidth;
  height: calc(100% - #{$menuWidth} - #{$topMenuPadding});
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  .entryContainerLeft {
    // display: flex;
    // flex-direction: column;

    .entry {
      display: flex;
      align-items: center;
      padding-left: 0.5rem;

      .text {
        display: flex;
        // padding: 1rem;
        white-space: nowrap;
        color: $text-light-01;
      }

      &:hover {
        background-color: $grey-50;
      }

      &.grey {
        background-color: $grey-10;

        &:hover {
          background-color: lighten($grey-10, 5%);
        }
      }
    }
  }
}

// .subEntry {
//   margin-left: 2.5rem;
//   padding-left: 1rem;
//   height: 3rem;
//   padding-right: 2rem;
//   display: flex;
//   align-items: center;

//   &:hover {
//     background-color: $grey-50;
//   }

//   .profile {
//     border-radius: 50%;
//     // border: 1px solid $grey-20;
//     overflow: hidden;
//     object-fit: cover;
//     min-width: 1.75rem;
//     height: 1.75rem;
//     margin-right: 0.5rem;
//   }

//   div {
//     .subTitle {
//       font-style: normal;
//       font-weight: bold;
//       font-size: 10px;
//       line-height: 120%;
//       /* identical to box height, or 12px */
//       color: $text-light-03;
//       letter-spacing: -0.02em;
//       text-transform: uppercase;
//     }
//   }

.rightMenu {
  grid-column: 3/4;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include max-MD {
    display: none;
  }
}

.menuOpen {
  $topMenuPadding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  max-width: 432px;
  padding: $topMenuPadding 0 0 0;
  background-color: white;
  z-index: 100;
  margin-top: 4rem;
  height: calc(100% - 4rem - #{$topMenuPadding});
  transition: all 0.15s ease-out;
  overflow-y: auto;

  @include max-MD {
    max-width: 384px;
  }

  @include max-S {
    max-width: 100%;
  }

  &.shop {
    margin-top: 5rem;
    height: calc(100% - 5rem - #{$topMenuPadding});
  }

  &.right {
    right: 0;
    transform: translateX(0);
    box-shadow: 0 100vw 0 100vw rgba(0, 0, 0, 0.25%);

    &.hidden {
      transform: translateX(100%);
      box-shadow: 0 100vw 0 100vw rgba(0, 0, 0, 0);
    }
  }

  &.left {
    left: 0;
    transform: translateX(0);
    box-shadow: 0 100vw 0 100vw rgba(0, 0, 0, 0.25%);

    &.hidden {
      transform: translateX(-100%);
      box-shadow: 0 100vw 0 100vw rgba(0, 0, 0, 0);
    }
  }

  .entryContainer {
    display: flex;
    flex-direction: column;

    .entry {
      display: flex;
      align-items: center;
      height: 3.5rem;
      padding-left: 1.5rem;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }

      svg {
        padding-right: 1rem;
        stroke: #333333;
      }

      img {
        padding-right: 1rem;
      }

      .text {
        // padding: 1rem;
        white-space: nowrap;
        color: $text-light-01;
        @include ptype-5;
        text-decoration: none;
      }

      $categoryVerticalShift: 8px;

      &.category {
        box-sizing: border-box;
        padding-top: $categoryVerticalShift; //this moves the category lower in his div as per design;
      }

      &.category:nth-child(1) {
        margin-top: -$categoryVerticalShift; //this shifts the first element up so it's allligned without the shift;
      }

      &.button {
        cursor: pointer;
        text-decoration: none;

        &:hover {
          background-color: $grey-50;
          text-decoration: none;
        }
      }

      &.grey {
        height: $menuWidth;
        background-color: $grey-10;

        &:hover {
          background-color: lighten($grey-10, 5%);
        }
      }
    }
  }
}

//   .subEntry {
//     margin-left: 2.5rem;
//     padding-left: 1rem;
//     height: 3rem;
//     padding-right: 2rem;
//     display: flex;
//     align-items: center;

//     &:hover {
//       background-color: $grey-50;
//     }

//     .profile {
//       border-radius: 50%;
//       // border: 1px solid $grey-20;
//       overflow: hidden;
//       object-fit: cover;
//       min-width: 1.75rem;
//       height: 1.75rem;
//       margin-right: 0.5rem;
//     }

//     div {
//       .subTitle {
//         font-style: normal;
//         font-weight: bold;
//         font-size: 10px;
//         line-height: 120%;
//         /* identical to box height, or 12px */
//         color: $text-light-03;
//         letter-spacing: -0.02em;
//         text-transform: uppercase;
//       }
//     }
//   }
// };

.pageContent {
  grid-column: 2/3;
  grid-row: 2/3;
  // overflow: auto;
  overflow-x: hidden;
  margin-left: 64px;

  // background-color: white;
  background-color: $grey-10;
  transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.thin {
    margin-left: 320px;
  }

  main {
    padding: 1.5rem;
    // min-height: 100vh;
    @include max-S {
      padding: 0;
    }
  }

  // display: flex;
  // flex-wrap: wrap;

  &.page-flex-column {
    display: flex;
    flex-direction: column;

    main {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  }
}

// .pageContent > div {
//   margin: 0.5rem;
// }

.productList {
  .longPoductCard:not(:first-child) {
    margin-top: 0.5rem;
  }
}

.flexSB {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flexVC {
  display: flex;
  align-items: center;
}

.hidden {
  visibility: hidden;
}

.productCard {
  box-sizing: border-box;
  $outerPadding: 1.5rem;
  width: calc(391px);
  min-height: 44.25rem;
  //width: calc(391px - 2 * #{$outerPadding});
  border: 1px solid #e6e6e6;
  padding: $outerPadding;
  background-color: $white;
  letter-spacing: -0.02em;
  color: $text-light-01;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.25s ease-out;
  // min-height: calc(580px - 2 * #{$outerPadding});
  &.no-height-prod-detail {
    min-height: unset;
    width: unset;
  }

  &.no-price-guest {
    min-height: unset;
  }

  @include min-LG {
    // min-height: calc(580px - 2 * #{$outerPadding});
  }
  @media (max-width: 767px) {
    min-height: auto;
    margin: 0 auto;
  }
  @media (max-width: 440px) {
    width: 100%;
  }

  > div:first-child {
    flex-grow: 1;
  }

  .strokeIt {
    svg {
      stroke: #333;
    }
  }

  .stock {
    //flex: 1 0 58px;
    height: 44px;
    //display: flex;
    //flex-direction: column;
  }

  .marginLeftAuto {
    margin-left: auto;
  }

  &.product-details {
    border: none;
    width: calc(100% - 3rem);
    box-sizing: inherit;
    @include max-S {
      width: 100%;
    }

    .title {
      margin-right: 2.5rem;
    }
  }

  .magic-button-container.grey {
    border: none;
  }

  &.long {
    flex-direction: row;
    $innerPadding: 1rem;
    $height: 7rem;
    align-items: center;
    padding: $innerPadding;
    height: auto;
    min-height: calc(#{$height} - 2 * #{$innerPadding});
    // border:solid 1px $white; // what??
    width: auto;
    justify-content: start;
    @media (max-width: 1199px) {
      .right-section {
        flex: 0 0 260px;

        .flex {
          justify-content: flex-end;

          + .flex {
            justify-content: flex-end;
          }
        }
      }
      .buttonContainerMainCTA {
        flex: unset !important;

        .label {
          padding: 0 1.5rem;
        }
      }
      .buttonContainerGrey {
        align-items: center;
        justify-content: center;
      }

    }

    .media-helper {
      width: calc(100% - 7rem);
      @media (max-width: 1200px) { //880
        flex-direction: column;

        .right-section {
          flex-basis: auto;
          margin-top: 16px;

          .flex {
            justify-content: flex-end;

            + .flex {
              justify-content: flex-end;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      box-sizing: border-box;
    }
    @media (max-width: 550px) { //450
      flex-direction: column;
      .image-wrap-long-card {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 0 16px;

        img {
          max-width: 350px; //100%;
          max-height: 100%;
        }
      }
      .media-helper {
        width: 100%;
      }
    }
  }

  .right-section_in-list {
    display: flex;

    > div + div {
      margin-left: 8px !important;
    }

    > div + button {
      margin-left: 8px !important;
    }

    button {
      border: none;
    }

    .price-fragment {
      margin-bottom: 0 !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .middle {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &:hover, &:active {
    border-color: $text-light-02;
  }

  .close {
    position: absolute;
    top: $outerPadding;
    right: $outerPadding;
  }

  .img-wrapper {
    background-color: $white;
    width: 100%;
    height: 160px;
    // padding-top: 56.25%;
    display: flex;
    justify-content: center;
    // overflow:hidden;
    // position: relative;
    // align-items: center;
    // justify-content: center;
    // text-align: center;
    > img {
      // position: absolute;
      // z-index: 1;
      // top:50%;
      // left:50%;
      // transform: translate(-50%,-50%);
      object-fit: contain;
      // width: auto;
      // height: auto;
    }

    &.guest {
      padding-top: 100%;
    }
  }

  .image-rating-section {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
  }

  .badge {
    $badgeHeight: 2.5rem;
    position: absolute;
    z-index: 2;
    top: -1px;
    left: 0;
    width: fit-content;
    height: $badgeHeight;
    // margin-top: -$outerPadding;
    // margin-left: -$outerPadding;
    padding-left: $outerPadding;
    padding-right: 2rem;
    @include ptype-4-bold;
    line-height: $badgeHeight;
    color: $white;
    clip-path: polygon(
                    0% 0%,
                    100% 0,
                    calc(100% - #{$badgeHeight} / 2) 100%,
                    0% 100%
    );
    @extend %badgeBackgroundColor;
  }

  .badge-long-card {
    @include badge-long-card;
  }

  .badge-inline {
    color: $white;
    width: fit-content;
    position: relative;
    @include ptype-3-strong;
    padding: 3px 15px 4px 8px;
    clip-path: polygon(100% 0, calc(100% - 8px) 100%, 0 100%, 0 0);
  }

  .image-wrap-long-card {
    @include image-wrap-long-card(6rem);
  }

  .right-section {
    // flex-basis: 350px;
    flex: 0 0 350px;
  }

  .horizontal-stock {
    flex: 1;
    margin-bottom: auto;

    > div + div {
      margin-left: 1rem;
    }

    align-items: flex-end;
  }

  .brandName {
    width: 100%;
    padding-top: 1.25rem;
    font-size: 16px;
    line-height: 120%;
    text-align: left;
  }

  .image {
    width: 100%;
    height: 160px;
    // background-color: $placeholderBlue;
    object-fit: contain;

    &.small {
      width: 120px;
      height: 120px;
    }
  }

  .price {
    margin-top: 0.5rem;
    display: flex;
    align-items: baseline;
  }

  .priceTop {
    @include ptype-6;

    .fullPrice {
      margin-left: 0.5rem;
      @include ptype-4;
      color: $text-light-02;
    }
  }

  .total-price {
    height: 4rem;
    padding: 0 1rem;
    margin-bottom: 0.5rem;
    background-color: $grey-10;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.center {
      justify-content: center;
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;

      > .top {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
      }
    }

    .youSave {
      margin-left: 0.5rem;
      @extend %redSmallCard;
    }

  }

  .priceBottom {
    align-items: center;

    .initialPrice {
      @include ptype-5-strike;

      // font-weight: 600;
      // font-size: 10px;
      // text-decoration-line: line-through;
      text-transform: uppercase;
      color: $red-10;
    }

    // .youSave {
    //   margin-left: 0.5rem;
    //   @extend %redSmallCard;
    // }
  }

  .rebate {
    $height: 1.5rem;
    @include ptype-3-strong;
    background-color: $green2;
    margin-top: 0.5rem;
    color: $white;
    height: $height;
    line-height: $height;
    text-align: center;
  }

  .title {
    margin-top: 0.5rem;
    // margin-bottom: 1rem;
    @include etype-1;
  }

  .input-zone {
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    height: 4rem;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    display: flex;
    border: 1px solid $grey-10;

    &:hover {
      border-color: $button-prime;
    }

    &:active {
      border-color: darken($button-prime, 10%);
    }

    &.orange {
      border-color: $alert-danger;

      input {
        color: $alert-danger;
      }
    }

    &.purple {
      border-color: $purple-60;

      input {
        color: $purple-60;
      }
    }

    &.red {
      border-color: $alert-red;

      input {
        color: $alert-red;
      }
    }

    .magic-button-container:last-of-type {
      border-left: 1px solid $grey-10;
    }
  }

  .min-q {
    flex-basis: 4rem;
    background-color: $grey-10;
    display: flex;
    align-items: center;
    justify-content: center;
    @include ptype-5-strong;
    color: $text-light-02;
    // margin-top: -1px;
    // margin-left: -1px;

    svg {
      margin-left: 4px;
      margin-top: 4px;
    }

  }

  .pc-quantity {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      border: none;
      flex: 1;
      padding: 1rem;
      padding-right: 8px;
      text-align: right;
      width: 2rem;
      color: $button-prime;

      &:focus {
        outline: none;
      }
    }

    .unit {
      flex: 1;
    }
  }

  .grey-option {
    padding: 0 1rem;
    border: 1px solid $grey-10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include ptype-5-strong;
    color: $text-light-01;
    height: 4rem;
    margin-bottom: 0.5rem;

    &.list {
      padding: 1rem;
      display: block;
      color: $text-light-02;
      height: auto;

      &.selected {
        border-color: darken($button-prime, 0%);
      }
    }

    &:hover {
      border-color: $button-prime;
    }

    &:active {
      border-color: darken($button-prime, 10%);
    }

    &.deal {
      border-color: $all-good;

      &:hover {
        // border-color: lighten($all-good, 10%);
        border-color: $button-prime;
      }

      &:active {
        border-color: darken($button-prime, 10%);
      }
    }
  }

  .price-fragment {
    > div:nth-child(3) {
      margin-left: auto;
    }
  }

  .gray-line {
    border-bottom: 1px solid $grey-10;
  }

  .back-button-wrapper {
    flex: 0 0;
  }

  .back-button:hover {
    cursor: pointer;
  }

  .already-negociated {
    background-color: $blue-60;
    //border-radius: 8px;
    padding: 1rem;

    .blue-right {
      color: $text-link;
    }
  }

  .volume-button {
    padding: 1.5rem;
    background-color: $purple-4;
    margin-top: auto;
  }
}

.grey-list-outer {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 0.5rem;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 13.33%, #ffffff 100%);
    width: 100%;
    height: 40px;
    // display: flex;
    left: 0;
    bottom: 0rem;
  }
}

.grey-list-wrapper {
  display: flex;
  max-height: 370px;
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;

  .grey-option {
    margin-right: 1px;

    &:last-child {
      margin-bottom: 80px;
    }
  }
}

.longPoductCard {
  $innerPadding: 1rem;
  $height: 7rem;
  display: flex;
  align-items: center;
  padding: $innerPadding;
  color: $text-light-01;
  height: auto;
  min-height: calc(#{$height} - 2 * #{$innerPadding});
  position: relative;
  background-color: #ffffff;
  transition: all 0.25s ease-out;
  border: solid 1px $white;
  @media (max-width: 767px) {
    min-height: auto;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
  }

  &:hover,
  &:active {
    border-color: $text-light-02;
  }

  .badge {
    $bannerHeight: 24px;
    height: $bannerHeight;
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    padding-left: calc(#{$innerPadding} * 0.7);
    padding-right: calc(#{$bannerHeight} / 2 + 0.5rem);
    @include ptype-3-strong;
    line-height: $bannerHeight;
    color: $white;
    clip-path: polygon(0% 0%, 100% 0, calc(100% - #{$bannerHeight} / 2) 50%, 100% 100%, 0% 100%);
    @extend %badgeBackgroundColor;
  }


  .img-wrapper {
    margin-right: 0.25rem;
    min-width: calc(#{$height} - 2 * #{$innerPadding});
    width: calc(#{$height} - 2 * #{$innerPadding});
    height: calc(#{$height} - 2 * #{$innerPadding});
    position: relative;

    > img {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      width: auto;
      height: auto;
      display: flex;
      max-height: 100%;
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    width: calc(100% - 86px);
    @media (max-width: 767px) {
      flex-direction: column;
      height: auto;
      width: 100%;
    }

    .data {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: calc(100% - 335px);
      @media (max-width: 1100px) {
        width: calc(100% - 215px);
      }
      @media (max-width: 767px) {
        width: 100%;
        margin: 0;
      }

      .brandName {
        @include ptype-5;
        padding: 0 1.5rem;
        box-sizing: border-box;
        @media (max-width: 767px) {
          padding: 0;
        }
      }

      .title {
        color: $text-light-01;
        margin-bottom: 0.25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 1.5rem;
        box-sizing: border-box;

        &.two-lines {
          white-space: normal;
        }

        @media (max-width: 767px) {
          white-space: normal;
          overflow: unset;
          text-overflow: unset;
          margin-bottom: 0.25rem;
          padding: 0;
        }
      }

      .priceContainer {
        display: flex;
        align-items: flex-end;

        div:not(:first-child) {
          margin-left: 0.5rem;
        }

        .initialPrice {
          @include ptype-5-strike;
          color: $red-10;
          line-height: 100%;
        }

        .price {
          @include ptype-6;
          line-height: 100%;
        }

        .fullPrice {
          font-size: 12px;
          color: $text-light-02;
          line-height: 100%;
        }

        .youSave {
          @extend %redSmallCard;
        }

        .rebate {
          @include ptype-3-strong;
          background-color: $green2;
          margin-left: 0.5rem;
          color: $white;
          text-align: center;
          padding: 0.2rem 0.75rem;
          border-radius: 4px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      flex: 0 0 320px;
      justify-content: flex-end;
      @media (max-width: 1100px) {
        flex: 0 0 210px;
      }
      @media (max-width: 767px) {
        flex: 0;
      }

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .buttons {
        display: flex;
        align-items: flex-end;
        margin-top: 0.5rem;

        .add {
          @extend %MainCTA;
          height: 3rem;
          line-height: 3rem;
        }

        .save {
          @extend %btnSecondary;
          margin-left: 0.5rem;
          height: 3rem;
          flex: 0 0 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .md-button {
        flex: 1 1;
      }
    }
  }
}

.grey-border {
  border: 1px solid $grey-10;
}

$circleHeight: 2rem;
.round-clear {
  height: $circleHeight;
  width: $circleHeight;
  cursor: pointer;
  margin-bottom: 4px;

  background-color: $grey-10;
  border-radius: calc(#{$circleHeight} / 2);
}

.text-button-prime {
  color: $button-prime;
  cursor: pointer;

  &:hover {
    color: lighten($button-prime, 10%);
  }

  &:active {
    color: darken($button-prime, 10%);
  }
}

.text-button-secondary {
  color: $text-light-03;
  cursor: pointer;

  &:hover {
    color: lighten($text-light-03, 10%);
  }

  &:active {
    color: darken($text-light-03, 10%);
  }
}


.itemQuantityBig {
  background-color: $grey-10;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 1rem;
  // margin-top: 0.5rem;
}

.itemQuantity {
  background-color: $grey-10;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  // align-items: center;
  height: 100%;
  padding-left: 1rem;
}

.central-modal-overlay {
  $modal-margin: 0rem;
  position: fixed;
  left: $modal-margin;
  top: $modal-margin;
  z-index: 3;
  height: calc(100vh - #{$modal-margin});
  width: calc(100vw - 2 * #{$modal-margin});
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  background: rgba(51, 51, 51, 0.8);
  padding: 24px;
  box-sizing: border-box;
  @media (max-width: 1023px) {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  &.fixed-modal {
    padding: 40px 80px 112px;
    @include max-S {
      padding: 0 0 112px;
    }

    .wizzard-form-wrapper {
      max-width: 100%;
      height: 100%;
      border: 1px solid #F3F3F3;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
      border-radius: 4px 4px 0 0;
      @include max-S {
        border-radius: 0;
      }

      .wizzard-footer {
        position: fixed;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;
        top: auto;
        padding-right: 80px;
        padding-left: 80px;
        @include max-S {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
        }
      }
    }
  }
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-y: hidden;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}
.grow {
  flex-grow: 1;
}
.grow-0 {
  flex-grow: 0;
}
.self-start {
  align-self: flex-start;
}
.self-center {
  align-self: center;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-start-1 {
  grid-column-start: 1;
}
.rounded-full {
  border-radius: 9999px;
}
.flex-col {
  flex-direction: column;
}
.avatar-input-container .circle-btn.add {
  pointer-events: auto;
}
.central-modal-overlay.fixed-modal .wizzard-form-wrapper {
  transform: none;
  -webkit-transition: none;
}